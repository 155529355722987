import { TextField, TextFieldProps } from '@/components/TextField';
import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { RegisterOptions } from 'react-hook-form';
import { useNewsletterSignupState } from './NewsletterSignup';

export interface NewsletterSignupInputFieldProps extends ExtendedStandaloneComponentProps<TextFieldProps> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  rules?: RegisterOptions<any>;
  size?: ClassNameProp<'default' | 'alternative'>;
}

export const NewsletterSignupInputFieldComponent: ExtendedStandaloneComponent<NewsletterSignupInputFieldProps> = ({
  $standalone,
  className,
  colors,
  rules,
  size,
  variant,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useNewsletterSignupState();

  const { name, options, ...standaloneProps } = $standalone ?? {};
  const {
    $caption,
    $group,
    $icon,
    $input,
    $label,
    className: standaloneClassName,
    ...standaloneOptions
  } = options ?? {};

  const { className: captionClassName, ...captionProps } = $caption ?? {};
  const { className: groupClassName, ...groupProps } = $group ?? {};
  // @ts-expect-error: `NewsletterSignup` is non-slot architecture
  const { className: iconClassName, ...iconProps } = $icon ?? {};
  const { className: inputClassName, ...inputProps } = $input ?? {};
  const { className: labelClassName, ...labelProps } = $label ?? {};

  const captionColorsClassName = propClassName('inputField_caption_colors', colors, 'primary');
  const captionSizeClassName = propClassName('inputField_caption_size', size, 'default');
  const captionVariantClassName = propClassName('inputField_caption_variant', variant, 'default');
  const captionComponentClassName = cn(
    captionColorsClassName,
    captionSizeClassName,
    captionVariantClassName,
    captionClassName,
  );

  const groupColorsClassName = propClassName('inputField_group_colors', colors, 'primary');
  const groupSizeClassName = propClassName('inputField_group_size', size, 'default');
  const groupVariantClassName = propClassName('inputField_group_variant', variant, 'default');
  const groupComponentClassName = cn(groupColorsClassName, groupSizeClassName, groupVariantClassName, groupClassName);

  const iconColorsClassName = propClassName('inputField_icon_colors', colors, 'primary');
  const iconSizeClassName = propClassName('inputField_icon_size', size, 'default');
  const iconVariantClassName = propClassName('inputField_icon_variant', variant, 'default');
  const iconComponentClassName = cn(iconColorsClassName, iconSizeClassName, iconVariantClassName, iconClassName);

  const inputColorsClassName = propClassName('inputField_input_colors', colors, 'primary');
  const inputSizeClassName = propClassName('inputField_input_size', size, 'default');
  const inputVariantClassName = propClassName('inputField_input_variant', variant, 'default');
  const inputComponentClassName = cn(inputColorsClassName, inputSizeClassName, inputVariantClassName, inputClassName);

  const labelColorsClassName = propClassName('inputField_label_colors', colors, 'primary');
  const labelSizeClassName = propClassName('inputField_label_size', size, 'default');
  const labelVariantClassName = propClassName('inputField_label_variant', variant, 'default');
  const labelComponentClassName = cn(labelColorsClassName, labelSizeClassName, labelVariantClassName, labelClassName);

  const colorsClassName = propClassName('inputField_colors', colors, 'primary');
  const sizeClassName = propClassName('inputField_size', size, 'default');
  const variantClassName = propClassName('inputField_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  const error = name && (errors[name]?.message as string);
  const status: TextFieldProps['status'] = error ? 'error' : 'default';
  const registration = name ? register(name, rules) : {};

  return (
    <TextField
      caption={error}
      options={{
        $caption: {
          className: captionComponentClassName,
          ...captionProps,
        },
        $group: {
          className: groupComponentClassName,
          ...groupProps,
        },
        $icon: {
          className: iconComponentClassName,
          ...iconProps,
        },
        $input: {
          className: inputComponentClassName,
          ...inputProps,
          ...registration,
        },
        $label: {
          className: labelComponentClassName,
          ...labelProps,
        },
        className: componentClassName,
        ...standaloneOptions,
      }}
      data-field={name}
      data-status={status}
      {...{ name, status }}
      {...standaloneProps}
      {...props}
    />
  );
};
