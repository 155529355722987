/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneFacebook as ResolvedStandaloneFacebook } from 'base/components/standalone/Facebook';

export const Facebook: typeof ResolvedStandaloneFacebook = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneFacebook {...props} />
        </ErrorBoundary>
    );
});

export type FacebookProps = PropsFromComponent<typeof Facebook>;

/** @deprecated Use slot architecture instead */
export const FacebookWith = (extras: DynamicStandaloneExtras): typeof Facebook => {
    return function Facebook(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Facebook');
        return <ResolvedStandaloneFacebook {...mergeProps({ options: { theme } }, props)} />;
    }
}