import { Article } from '@/components/Article';
import { LabradorComponent } from '@/types/component';
import { logger } from '@/utils/logger';

export const LabradorArticle: LabradorComponent = ({ data, meta, descendants, ...props }) => {
  const { nativeAdLabel, isNativeAd } = meta ?? {};
  const { affiliateLabel, hasAffiliate, badgeData } = data ?? {};
  const { name: badgeName } = badgeData ?? {};
  const articleDisclosureLabels = [];

  if (isNativeAd) {
    if (!nativeAdLabel) {
      logger.warn('Missing nativeAdLabel in LabradorArticle, setting to default value.');
    }
    articleDisclosureLabels.push(nativeAdLabel ?? 'ANNONS');
  }

  if (hasAffiliate && affiliateLabel) {
    articleDisclosureLabels.push(affiliateLabel);
  }

  return (
    <Article
      hide={{ badge: true }}
      badge={{ fragment: badgeName }}
      {...{ descendants, articleDisclosureLabels }}
      {...props}
    />
  );
};
