import { Link } from '@/components/Link';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { KeesingGames } from 'base/components/KeesingGames/KeesingGames';
import getConfig from 'modules/config';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useSearchParam } from 'react-use';
import Head from 'next/head';

export interface StandaloneKeesingGamesProps extends StandaloneComponentProps<typeof KeesingGames> {}

export const StandaloneKeesingGames: StandaloneComponent<StandaloneKeesingGamesProps> = ({ ...props }) => {
  const { customerId, scriptPath, stylesPath, frontPagePathname } = getConfig('keesingGames');
  const isPlaying = Boolean(useSearchParam('gametype'));

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!customerId || !scriptPath) return null;

  return (
    <>
      <Head>
        <link rel="stylesheet" href={stylesPath} />
      </Head>
      <KeesingGames {...props}>
        {isClient && isPlaying && (
          <Link
            href={frontPagePathname}
            content={
              <KeesingGames.LinkText>
                <KeesingGames.Icon name="chevronLeft" />
                Gå tillbaka till alla spel
              </KeesingGames.LinkText>
            }
          />
        )}
        <KeesingGames.PuzzlePortal
          data-playerpath={frontPagePathname}
          data-customerid={customerId}
          id="puzzle-portal"
        />
        <Script type="text/javascript" src={scriptPath} data-wlpp-bundle={isPlaying ? 'player' : 'portal'} />
      </KeesingGames>
    </>
  );
};
