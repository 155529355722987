import { StrossleTheme } from '@/components/Strossle/theme';
import { GenericSlot } from 'base/components/GenericSlot';
import getConfig from 'modules/config';
import Script from 'next/script';
import { useId } from 'react';

export const Strossle = GenericSlot({
  theme: StrossleTheme,
  as: 'div',
  render: function Render({ props }) {
    const id = useId().replace(/:/g, '');
    const strossleId = getConfig('strossle')?.id;

    return (
      <div {...props}>
        <Script
          id="strossle-v2-widget-sdk"
          src="https://assets.strossle.com/strossle-widget-sdk/1/strossle-widget-sdk.js"
        />
        <div data-widgetid={strossleId} id={`strossle-widget-${id}`}></div>
        <Script id={`load-strossle-${id}`}>
          {`window.strossle=window.strossle||function(){(strossle.q=strossle.q||[]).push(arguments)};
        strossle('${strossleId}', '#strossle-widget-${id}');`}
        </Script>
      </div>
    );
  },
});
