/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorBanner as ResolvedLabradorBanner } from 'base/components/labrador/Banner';

export const LabradorBanner: typeof ResolvedLabradorBanner = withLabradorInstanceof((props) => {
    return <ResolvedLabradorBanner {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorBannerProps = PropsFromComponent<typeof LabradorBanner>;
