import { LabradorJwVideoArticle } from '@/components/labrador/JwVideo/Article';
import { LabradorJwVideoTeaser } from '@/components/labrador/JwVideo/Teaser';
import { LabradorComponent } from '@/types/component';

export const LabradorJwVideo: LabradorComponent = ({ data, type, meta, descendants }) => {
  const { enableShop, enableTeaser } = data;
  if (enableShop) return null;

  if (enableTeaser) return <LabradorJwVideoTeaser data={data} type={type} meta={meta} descendants={descendants} />;

  return <LabradorJwVideoArticle data={data} type={type} meta={meta} descendants={descendants} />;
};
