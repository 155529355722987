import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isSwedishLetter } from '@/utils/isSwedishLetter';
import { upperFirst } from 'lodash-es';
import { ReactNode } from 'react';
import { TagsIndex } from './TagsIndex';

interface TagGroup {
  letter: string;
  tags?: string[];
}

export interface StandaloneTagsIndexProps extends StandaloneComponentProps<typeof TagsIndex> {
  headline?: ReactNode;
  tags?: string[];
}

const other = 'Övrigt';

export const StandaloneTagsIndex: StandaloneComponent<StandaloneTagsIndexProps> = ({ headline, tags, ...props }) => {
  const tagsObject =
    tags &&
    tags.reduce((acc: { [key: string]: string[] }, item: string) => {
      const letter: string = item.charAt(0);

      if (!letter.match(/[a-zåäö]/i)) {
        return {
          ...acc,
          [other]: [...(acc[other] ?? []), item],
        };
      }

      return {
        ...acc,
        [letter]: [...(acc[letter] ?? []), item],
      };
    }, {});

  const tagsArray: TagGroup[] = tagsObject
    ? Object.keys(tagsObject).map((key) => ({
        letter: key,
        tags: (tagsObject as { [x: string]: string[] })[key].sort(),
      }))
    : [];

  tagsArray.sort((a, b) => {
    if (a.letter === other) {
      return 1;
    }

    if (b.letter === other) {
      return -1;
    }

    if (isSwedishLetter(a.letter) && isSwedishLetter(b.letter)) {
      return a.letter.localeCompare(b.letter);
    }

    return a.letter < b.letter ? -1 : a.letter > b.letter ? 1 : 0;
  });

  return (
    <TagsIndex {...props}>
      {headline && <TagsIndex.Headline>{headline}</TagsIndex.Headline>}
      {tagsArray &&
        tagsArray.map((tagGroup) => (
          <TagsIndex.Group key={tagGroup.letter}>
            <TagsIndex.Letter>{upperFirst(tagGroup.letter)}</TagsIndex.Letter>
            <TagsIndex.Tags>
              {tagGroup.tags?.map((tagItem, index) => (
                <TagsIndex.Tag key={index} value={tagItem} link={{ href: `/etikett/${tagItem}` }} />
              ))}
            </TagsIndex.Tags>
          </TagsIndex.Group>
        ))}
    </TagsIndex>
  );
};
