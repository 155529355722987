import { StandaloneComponent } from '@/types/component';
import Script from 'next/script';

export interface StandaloneRecaptchaProps {}

export const StandaloneRecaptcha: StandaloneComponent<StandaloneRecaptchaProps> = () => {
  if (!process.env.OCELOT_RECAPTCHA_KEY) {
    return null;
  }
  return (
    <Script
      src={`https://www.google.com/recaptcha/enterprise.js?render=${process.env.OCELOT_RECAPTCHA_KEY}`}
      async
      defer
      key="recaptcha"
    />
  );
};
