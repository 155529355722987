import { ImageProps } from '@/components/Image';
import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import React, { Fragment, ReactNode } from 'react';
import { AffiliateItem } from './AffiliateItem';

export interface AffiliateItemProduct {
  headline?: ReactNode;
  image?: ImageProps;
  links?: {
    link?: OptionalLinkProps;
    caption?: ReactNode;
    affiliate?: ReactNode;
  }[];
  vendor?: ReactNode;
}

export interface StandaloneAffiliateItemProps extends StandaloneComponentProps<typeof AffiliateItem> {
  counter?: ReactNode;
  headline?: ReactNode;
  description?: ReactNode;
  subtitle?: ReactNode;
  products?: AffiliateItemProduct[];
}

export const StandaloneAffiliateItem: StandaloneComponent<StandaloneAffiliateItemProps> = ({
  counter,
  headline,
  description,
  subtitle,
  products,
  ...props
}) => {
  if (!products?.length) {
    return null;
  }

  return (
    <AffiliateItem
      options={{
        multiple: products.length > 1,
      }}
      {...props}
    >
      {(counter || headline || subtitle || description) && (
        <AffiliateItem.Group>
          {counter && <AffiliateItem.Counter>{counter}</AffiliateItem.Counter>}
          {headline && <AffiliateItem.Headline>{headline}</AffiliateItem.Headline>}
          {subtitle && <AffiliateItem.Subtitle>{subtitle}</AffiliateItem.Subtitle>}
          {description && <AffiliateItem.Description>{description}</AffiliateItem.Description>}
        </AffiliateItem.Group>
      )}

      {products?.map(({ image, headline, links, vendor }, index) => (
        <Fragment key={index}>
          <AffiliateItem.ProductImage
            {...mergeProps(
              {
                options: {
                  $group: {
                    className: !props.options?.multiple && `md:row-start-1 md:row-end-5`,
                  },
                  $caption: {
                    $byline: {
                      className: 'hidden',
                    },
                    $text: {
                      className: 'hidden',
                    },
                  },
                },
              },
              image,
            )}
          />

          {(headline || links?.length || vendor) && (
            <AffiliateItem.Product>
              {headline && <AffiliateItem.ProductHeadline>{headline}</AffiliateItem.ProductHeadline>}

              {links?.map((productLink, index) => (
                <React.Fragment key={index}>
                  {productLink.affiliate && (
                    <AffiliateItem.ProductVendor>{productLink.affiliate}</AffiliateItem.ProductVendor>
                  )}
                  <OptionalLink
                    target="_blank"
                    {...productLink.link}
                    content={<AffiliateItem.ProductCaption>{productLink.caption}</AffiliateItem.ProductCaption>}
                  />
                </React.Fragment>
              ))}

              {vendor && <AffiliateItem.ProductVendor>{vendor}</AffiliateItem.ProductVendor>}
            </AffiliateItem.Product>
          )}
        </Fragment>
      ))}
    </AffiliateItem>
  );
};
