import { SliderSlide, type SliderSlideProps } from '@/components/Slider/Slide';
import { SliderSwiper, type SliderSwiperProps } from '@/components/Slider/Swiper';
import { ClassNameProvider, componentTheme } from '@/styles/BlogSlider';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/mergeProps';
import { BlogSlider } from './BlogSlider';

SliderSlide.displayName = 'SwiperSlide'; // Required for `Swiper` to work

export interface Blogger {
  url: string;
  slug: string;
  title: string;
  author: string;
  sliderImage: string;
}

interface ExtendedOptions {
  $slide?: SliderSlideProps['options'];
  $swiper?: SliderSwiperProps['options'];
}

export interface StandaloneBlogSliderProps extends StandaloneComponentProps<typeof BlogSlider, ExtendedOptions> {
  headline?: React.ReactNode;
  bloggers?: Blogger[];
}

export const StandaloneBlogSlider: StandaloneComponent<StandaloneBlogSliderProps> = ({
  headline,
  bloggers,
  options,
  ...props
}) => {
  const { $swiper, $slide, ...$base } = options ?? {};

  if (!bloggers?.length) return null;

  const slides = bloggers.map((blogger: Blogger) => (
    <SliderSlide
      key={blogger.slug}
      url={blogger.url}
      title={blogger.title}
      imageSrc={blogger.sliderImage}
      options={mergeOptions({ theme: componentTheme('slide') }, $slide)}
    />
  ));

  return (
    <ClassNameProvider value={$base}>
      <BlogSlider {...$base} {...props}>
        <SliderSwiper
          content={slides}
          title={headline}
          options={mergeOptions({ theme: componentTheme('swiper') }, $swiper)}
        />
      </BlogSlider>
    </ClassNameProvider>
  );
};
