import { getSavedArticlesIds, removeSavedArticle, saveArticle } from 'lib/alltIdMyData';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useUserAuth from './useUserAuth';

export const useSavedArticles = ({
  articleId,
  notLoggedInCallback,
}: {
  notLoggedInCallback?: () => void;
  articleId: string;
}): [boolean, () => void] => {
  const [isSaved, setIsSaved] = useState(false);
  const [savedArticles, setSavedArticles] = useState<string[]>([]);
  const { isUserLoggedIn } = useUserAuth();

  useEffect(() => {
    if (!isUserLoggedIn) {
      setSavedArticles([]);
    }

    getSavedArticlesIds()?.then((response) => setSavedArticles(Object.keys(response?.data?.response ?? {})));
  }, [isUserLoggedIn]);

  useEffect(() => {
    setIsSaved(isUserLoggedIn ? savedArticles.includes(articleId) : false);
  }, [articleId, savedArticles, isUserLoggedIn]);

  const toggleSaveArticle = useCallback(() => {
    switch (true) {
      case !isUserLoggedIn:
        notLoggedInCallback?.();
        break;

      case !isSaved:
        saveArticle({ articleId })?.then(() => setIsSaved(true));
        break;

      default:
        removeSavedArticle({ articleId })?.then(() => setIsSaved(false));
        break;
    }
  }, [isSaved, isUserLoggedIn, notLoggedInCallback, articleId]);

  return useMemo(() => [isSaved, toggleSaveArticle], [isSaved, toggleSaveArticle]);
};
