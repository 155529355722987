import { useClassName } from '@/styles/AffiliateGallery';
import { PropsFromComponent, PropsFromSubcomponents } from '@/types/component';
import { merge } from '@/utils/merge';
import { GenericComponentFactory } from 'base/components/GenericComponent';

export type AffiliateGalleryProps = PropsFromComponent<typeof AffiliateGallery>;
export type AffiliateGallerySubcomponentProps = PropsFromSubcomponents<typeof AffiliateGallery>;

const $ = GenericComponentFactory({ useClassName });

const Base = $({ as: 'section' });
const Disclaimer = $({ as: 'span', styles: { key: 'disclaimer' } });
const DisclaimerLine = $({ styles: { key: 'disclaimer_line' } });
const Title = $({ styles: { key: 'title' } });
const Price = $({ styles: { key: 'price' } });

export const AffiliateGallery = merge(Base, {
  Disclaimer: merge(Disclaimer, {
    Line: DisclaimerLine,
  }),
  Title,
  Price,
});
