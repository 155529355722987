import { Blip as StandaloneBlip } from '@/components/Blip';
import { DirektTheme } from '@/components/Direkt/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: DirektTheme });

const Base = $({ as: 'section', slot: 'base' });
const Group = $({ as: 'div', slot: 'group' });
const Blip = $({ as: StandaloneBlip, theme: DirektTheme.Blip });
const Heading = $({ as: 'h1', slot: 'heading' });
const Label = $({ as: 'span', slot: 'label' });
const Description = $({ as: 'div', slot: 'description' });

export const Direkt = Object.assign(Base, {
  Group,
  Blip,
  Heading,
  Description,
  Label,
});
