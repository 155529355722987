/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorQuotebox as ResolvedLabradorQuotebox } from 'base/components/labrador/Quotebox';

export const LabradorQuotebox: typeof ResolvedLabradorQuotebox = withLabradorInstanceof((props) => {
    return <ResolvedLabradorQuotebox {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorQuoteboxProps = PropsFromComponent<typeof LabradorQuotebox>;
