import c from '@/styles/Facebook';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import Script from 'next/script';

export interface FacebookProps extends ComponentProps<'div'> {
  isVideo: boolean;
  url: string;
  videoWidth?: number;
  variant?: ClassNameProp<'default'>;
}

const FacebookComponent: Component<FacebookProps> = ({
  isVideo,
  url,
  videoWidth,
  variant,
  className,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const facebookClassName = isVideo ? 'fb-video' : 'fb-post';
  const componentClassName = cn(variantClassName, facebookClassName, className);

  return (
    <>
      {/* Max width for fb post is 750px, video width is not limited, docs -> https://developers.facebook.com/docs/plugins/embedded-posts */}
      <div data-width={isVideo ? '' : '750'} className={componentClassName} data-href={url} data-lazy {...props} />
      <Script src="https://connect.facebook.net/sv_SE/sdk.js#xfbml=1&version=v4.0&appId=1390493017945983&autoLogAppEvents=1" />
    </>
  );
};

export const Facebook = Object.assign(FacebookComponent, {});
