/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandalonePre as ResolvedStandalonePre } from 'base/components/standalone/Pre';

export const Pre: typeof ResolvedStandalonePre = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandalonePre {...props} />
        </ErrorBoundary>
    );
});

export type PreProps = PropsFromComponent<typeof Pre>;

/** @deprecated Use slot architecture instead */
export const PreWith = (extras: DynamicStandaloneExtras): typeof Pre => {
    return function Pre(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Pre');
        return <ResolvedStandalonePre {...mergeProps({ options: { theme } }, props)} />;
    }
}