/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneRecaptcha as ResolvedStandaloneRecaptcha } from 'base/components/standalone/Recaptcha';

export const Recaptcha: typeof ResolvedStandaloneRecaptcha = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneRecaptcha {...props} />
        </ErrorBoundary>
    );
});

export type RecaptchaProps = PropsFromComponent<typeof Recaptcha>;

/** @deprecated Use slot architecture instead */
export const RecaptchaWith = (extras: DynamicStandaloneExtras): typeof Recaptcha => {
    return function Recaptcha(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Recaptcha');
        return <ResolvedStandaloneRecaptcha {...mergeProps({ options: { theme } }, props)} />;
    }
}