/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneYoutube as ResolvedStandaloneYoutube } from 'base/components/standalone/Youtube';

export const Youtube: typeof ResolvedStandaloneYoutube = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneYoutube {...props} />
        </ErrorBoundary>
    );
});

export type YoutubeProps = PropsFromComponent<typeof Youtube>;

/** @deprecated Use slot architecture instead */
export const YoutubeWith = (extras: DynamicStandaloneExtras): typeof Youtube => {
    return function Youtube(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Youtube');
        return <ResolvedStandaloneYoutube {...mergeProps({ options: { theme } }, props)} />;
    }
}