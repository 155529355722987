import { LinkWith } from '@/components/Link';
import { useClassName, useTheme } from '@/styles/SliderSlide';
import { ComponentProps } from '@/types/component';
import { GenericComponentFactory } from 'base/components/GenericComponent';
import { SwiperSlide } from 'swiper/react';
import { SlideImageComponent } from './Slide.Image';

export interface SlideProps extends ComponentProps<typeof SwiperSlide> {}

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: SwiperSlide });
const Caption = GenericComponent({ styles: { key: 'caption' } });

const Link = LinkWith({ theme: { useTheme } });

export const Slide = Object.assign(Base, {
  Caption,
  Image: SlideImageComponent,
  Link,
});
