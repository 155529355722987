import { SliderSwiper } from '@/components/Slider/Swiper';
import { Components } from '@/components/standalone';
import { LabradorComponent } from '@/types/component';
import { pathToComponent } from '@/utils/toComponent';

export const LabradorSlider: LabradorComponent = ({ type, data, meta }) => {
  const name = ('Slider' + (pathToComponent(meta.path) || 'Swiper')) as keyof typeof Components;
  const SliderComponent = Components[name] as typeof SliderSwiper;

  return <SliderComponent {...data} />;
};
