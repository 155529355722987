import { Icon } from '@/components/Icon';

export const getCountdownCopy = ({
  courseStarted,
  isUserSubscribed,
  isMyPage,
}: {
  courseStarted: boolean;
  isUserSubscribed: boolean;
  isMyPage: boolean;
}) => {
  if (isMyPage && courseStarted && isUserSubscribed) {
    return {
      title: 'Vi är igång! I 21 dagar kommer vi peppa och coacha dig till nya hälsosamma vanor.',
      description: '',
      button: {
        content: (
          <>
            Gå till utmaningen
            <Icon name="chevronRight" />
          </>
        ),
        link: { href: '/21-dagar-start' },
      },
    };
  }

  if (!courseStarted && !isUserSubscribed) {
    return {
      title: 'ANTAR DU UTMANINGEN?',
      description:
        'Nya kost- och träningsvanor på 21 dagar. Få tillgång till träningsschema och inspelade pass – nya kostplaner varje vecka och över hundra utvalda recept. Den 27 januari kör vi!',
      button: {
        content: (
          <>
            Läs mer och anmäl dig här!
            <Icon name="chevronRight" />
          </>
        ),
        link: { href: '/21-dagar' },
      },
    };
  }

  if (!courseStarted && isUserSubscribed) {
    return {
      title: 'Snart börjar utmaningen!',
      description:
        'Nya kost- och träningsvanor på 21 dagar. Få tillgång till träningsschema och inspelade pass – nya kostplaner varje vecka och över hundra utvalda recept. Den 27 januari kör vi!',
      button: {
        content: (
          <>
            Läs mer här innan vi börjar
            <Icon name="chevronRight" />
          </>
        ),
        link: { href: '/nyttig-mat/21-dagar-fore-start/10551729' },
      },
    };
  }

  return { hideCountdown: true };
};
