import { Youplay } from '@/components/Youplay';
import { LabradorComponent } from '@/types/component';
import { getB2BVideoInfo, getVideoOptions } from 'base/components/JwVideo/Article/utils/dataParser';

export const LabradorYouplay: LabradorComponent = ({ data, meta }) => {
  const videoOptions = getVideoOptions(data);
  const b2bVideoInfo = getB2BVideoInfo(data);

  const { enableDesc, fieldSettings, durationMinutes, playerID, teaserLink, videoMetaData } = data;

  const titleTextSize = fieldSettings?.title?.textSize;

  if (!playerID) {
    return null;
  }

  return (
    <Youplay
      playerSrc={`https://cdn.jwplayer.com/libraries/${playerID}.js`}
      videoData={videoOptions}
      b2bVideo={b2bVideoInfo}
      showDescription={enableDesc}
      durationMinutes={durationMinutes}
      videoPageLink={teaserLink}
      titleTextSize={titleTextSize}
      metadata={videoMetaData}
      data-content-index={meta.contentIndex}
    />
  );
};
