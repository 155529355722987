import { Button as StandaloneButton } from '@/components/Button';
import { Icon as StandaloneIcon } from '@/components/Icon';
import { Image as StandaloneImage } from '@/components/Image';
import { SavedArticlesTheme } from '@/components/SavedArticles/theme';
import { Tag as StandaloneTag } from '@/components/Tag';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: SavedArticlesTheme });

const Base = $({ as: 'div', slot: 'base' });
const Wrapper = $({ as: 'div', slot: 'wrapper' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Empty = $({ as: 'p', slot: 'empty' });
const Article = $({ as: 'article', slot: 'article' });
const ArticleHeader = $({ as: 'div', slot: 'articleHeader' });
const ArticleCaption = $({ as: 'h4', slot: 'articleCaption' });
const ArticleDescription = $({ as: 'p', slot: 'articleDescription' });
const ArticleTag = GenericSlot({ as: StandaloneTag, theme: SavedArticlesTheme.Tag });
const ArticleIcon = GenericSlot({ as: StandaloneIcon, theme: SavedArticlesTheme.Icon });
const ArticleImage = GenericSlot({ as: StandaloneImage, theme: SavedArticlesTheme.Image });

// TODO: move Button to new architecture
const Button = StandaloneButton;

export const SavedArticles = Object.assign(Base, {
  Wrapper,
  Headline,
  Empty,
  Article,
  ArticleHeader,
  ArticleCaption,
  ArticleDescription,
  ArticleTag,
  ArticleIcon,
  ArticleImage,
  Button,
});
