import { Image } from '@/components/Image';
import { LabradorComponent } from '@/types/component';
import { mapLabradorImageToStandaloneImage } from '@/utils/image';

export const LabradorLabradorImage: LabradorComponent = ({ type, data, meta, descendants }) => {
  const parent = meta.path.split('/').pop() || '';

  // Caption should never be shown on article teasers
  if (parent === 'articleTeaser') data.showCaption = false;

  return <Image {...mapLabradorImageToStandaloneImage({ type, data, meta, children: descendants })} />;
};
