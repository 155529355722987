import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Content } from '@/types/content';
import { renderBySelector } from 'modules/dynamic/components';
import { Front } from './Front';

export interface StandaloneFrontProps extends StandaloneComponentProps<typeof Front> {
  descendants: Content[];
}

export const StandaloneFront: StandaloneComponent<StandaloneFrontProps> = ({ descendants, ...props }) => {
  return (
    <>
      {renderBySelector('trendingMenu', descendants)}
      {renderBySelector('highlightedArticles', descendants)}

      <Front data-cts-scroll-depth-item="front" {...props}>
        {renderBySelector('lab-dz-1', descendants)}
      </Front>
    </>
  );
};
