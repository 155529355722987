import { LabradorComponent } from '@/types/component';

export const MabraLabradorCourseDays: LabradorComponent = ({ meta }) => {
  const days = Array.from({ length: meta.days || 7 }, (_, index) => index + 1);

  return (
    <div className="flex flex-row flex-wrap justify-center gap-x-1.5 gap-y-2 px-3 sm:gap-1">
      {days.map((day) => {
        return (
          <a
            key={day}
            href={`#dag-${day}`}
            className="hover:outline-yellow-sea-400 flex aspect-square w-19 flex-col items-center justify-center rounded bg-white outline outline-1 outline-transparent transition-all hover:shadow-[inset_0_0_3px_0_rgba(0,0,0,0.22)]"
          >
            <span className="text-headline-xs">Dag</span>
            <span className="text-headline-md">{day}</span>
          </a>
        );
      })}
    </div>
  );
};
