import { useClassName } from '@/styles/BlogSlider';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface BlogSliderProps extends ComponentProps<'div'> {}

const BlogSliderComponent: Component<BlogSliderProps> = ({ children, ...props }) => {
  const className = useClassName('', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};

export const BlogSlider = Object.assign(BlogSliderComponent, {});
