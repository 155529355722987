/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneList as ResolvedStandaloneList } from 'base/components/standalone/List';

export const List: typeof ResolvedStandaloneList = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneList {...props} />
        </ErrorBoundary>
    );
});

export type ListProps = PropsFromComponent<typeof List>;

/** @deprecated Use slot architecture instead */
export const ListWith = (extras: DynamicStandaloneExtras): typeof List => {
    return function List(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'List');
        return <ResolvedStandaloneList {...mergeProps({ options: { theme } }, props)} />;
    }
}