import { tw } from '@/utils/tw';
import { default as BaseShowHero } from 'base/components/ShowHero/theme';

const { Tag } = BaseShowHero;

const ShowHero = tw.theme({
  extend: BaseShowHero,
  slots: {
    base: 'to-cherry-blossom-50 from-white',
    content: `gap-4`,
    button: '[&>a]:text-black [&_path]:fill-black [&_svg]:mt-0.5',
    headline: '-mt-4 text-headline-lg md:text-headline-xl',
    description: 'text-body-sm md:text-body-md',
  },
});

export default Object.assign(ShowHero, { Tag });
