import { ArticleTeaserDefault, ArticleTeaserDefaultProps } from '@/components/ArticleTeaser/Default';
import { componentClassName } from '@/styles/RecommendedArticles';
import { ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';
import Tracking from 'base/components/TrackingComponent';

export interface RecommendedArticlesListItemTeaserProps
  extends ExtendedStandaloneComponentProps<ArticleTeaserDefaultProps> {}

export const RecommendedArticlesListItemTeaserComponent: ExtendedStandaloneComponent<
  RecommendedArticlesListItemTeaserProps
> = ({ $standalone, variant, size, colors, ...props }) => {
  const { headline, image, description, caption, ...standaloneProps } = $standalone ?? {};

  const commonClassNameProps = { colors, size, variant };
  const common = [commonClassNameProps] as const;

  const captionClassName = componentClassName('list_item_teaser_caption', ...common);
  const descriptionClassName = componentClassName('list_item_teaser_description', ...common);
  const headlineClassName = componentClassName('list_item_teaser_headline', ...common);
  const imageClassName = componentClassName('list_item_teaser_image', ...common);
  const groupClassName = componentClassName('list_item_teaser_group', ...common);
  const className = componentClassName('list_item_teaser', ...common);

  return (
    <Tracking>
      <ArticleTeaserDefault
        image={mergeProps(
          {
            options: { className: imageClassName },
          },
          image,
        )}
        caption={caption}
        description={description}
        headline={headline}
        {...mergeProps(
          {
            options: {
              $caption: {
                className: captionClassName,
              },
              $description: {
                className: descriptionClassName,
              },
              $headline: {
                className: headlineClassName,
              },
              $group: {
                className: groupClassName,
              },
              className,
            },
          },
          standaloneProps,
        )}
        {...props}
      />
    </Tracking>
  );
};
