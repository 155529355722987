import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Facebook, FacebookProps } from './Facebook';

export interface StandaloneFacebookProps extends StandaloneComponentProps {
  url: FacebookProps['url'];
  options: Omit<FacebookProps, 'url'>;
}

export const StandaloneFacebook: StandaloneComponent<StandaloneFacebookProps> = ({ url, options, ...props }) => {
  return <Facebook url={url} {...options} {...props} />;
};
