import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';

export const initAdunitElements = (
  adsUniqueId?: string,
  isNativeAd?: boolean,
  isSecondArticle?: boolean,
) => {
  if (isServer()) return;

  if (isNativeAd) {
    return;
  }

  if (typeof window.DFPInit !== 'function') return;

  if (!adsUniqueId) {
    return logger.warn(`Ads unique ID is missing`);
  }

  window.adsUniqueId = `${adsUniqueId}`;
  window.DFPInit(adsUniqueId, { isSecondArticle });
};
