export function widthToClass(width: { desktop?: number; mobile?: number }) {
  const classMap: { [w: string]: string } = {
    '8.333': 'w-1/12',
    '8.33': 'w-1/12',
    '16.667': 'w-2/12',
    '16.67': 'w-2/12',
    '25': 'w-3/12',
    '33.333': 'w-4/12',
    '33.33': 'w-4/12',
    '41.667': 'w-5/12',
    '41.67': 'w-5/12',
    '50': 'w-6/12',
    '58.333': 'w-7/12',
    '58.33': 'w-7/12',
    '66.667': 'w-8/12',
    '66.67': 'w-8/12',
    '75': 'w-9/12',
    '83.333': 'w-10/12',
    '83.33': 'w-10/12',
    '91.667': 'w-11/12',
    '91.67': 'w-11/12',
    '100': 'w-full',
  };

  const mobileClass = String(classMap[width.mobile || 100]);
  const desktopClass = String(classMap[width.desktop || 100]);

  return `${mobileClass} sm:${desktopClass}`;
}
