import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ScrollPageState } from 'lib/hooks/useScrollPageLogic';
import { useEffect } from 'react';
import { ScrollPageControls, ScrollPageControlsProps } from './ScrollPageControls';
import { ScrollPageControlsButtonProps } from './ScrollPageControls.Button';
import { ScrollPageControlsLoaderProps } from './ScrollPageControls.Loader';

export interface StandaloneScrollPageControlsProps extends StandaloneComponentProps {
  state?: ScrollPageState;
  loadNextPage?: () => Promise<void>;
  options?: ScrollPageControlsProps & {
    $button?: ScrollPageControlsButtonProps;
    $loader?: ScrollPageControlsLoaderProps;
  };
}

export const StandaloneScrollPageControls: StandaloneComponent<StandaloneScrollPageControlsProps> = ({
  state,
  loadNextPage,
  options,
  ...props
}) => {
  const { $button, $loader, ...baseProps } = options ?? {};
  const { $standalone: $buttonStandalone, ...buttonProps } = $button ?? {};
  const { options: buttonStandaloneOptions, ...buttonStandaloneProps } = $buttonStandalone ?? {};

  const { isScrollLoadDisabled, showLoadMoreButton, isScrollPageLoading, scrollLoaderRef } = state ?? {};

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach(async ({ isIntersecting }) => {
        if (isIntersecting && !isScrollPageLoading) {
          await loadNextPage?.();
        }
      });
    };

    const observer = new IntersectionObserver(callback);
    const { current } = scrollLoaderRef ?? {};
    if (current) observer.observe(current);

    return () => {
      if (current) observer.unobserve(current);
    };
  }, [scrollLoaderRef, isScrollPageLoading, loadNextPage]);

  const showButton = showLoadMoreButton && !isScrollPageLoading;

  const button = (
    <ScrollPageControls.Button
      $standalone={{
        content: 'Mer',
        options: {
          onClick: loadNextPage,
          disabled: isScrollPageLoading,
          ...buttonStandaloneOptions,
        },
        ...buttonStandaloneProps,
      }}
      {...buttonProps}
    />
  );

  const loader = (
    <ScrollPageControls.Loader
      {...mergeProps(
        {
          options: {
            ref: scrollLoaderRef,
          },
        },
        $loader,
      )}
    />
  );

  return (
    !isScrollLoadDisabled && (
      <ScrollPageControls {...baseProps} {...props}>
        {showButton ? button : loader}
      </ScrollPageControls>
    )
  );
};
