import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { QuoteBox, QuoteBoxProps } from './Quotebox';
import { QuoteboxAuthorProps } from './Quotebox.Author';
import { QuoteboxQuotationMarkProps } from './Quotebox.QuotationMark';
import { QuoteboxQuoteProps } from './Quotebox.Quote';

export interface StandaloneQuoteboxProps extends StandaloneComponentProps {
  author?: ReactNode;
  quote?: ReactNode;
  quotationMark?: ReactNode;
  options?: QuoteBoxProps & {
    $author?: QuoteboxAuthorProps;
    $quote?: QuoteboxQuoteProps;
    $quotationMark?: QuoteboxQuotationMarkProps;
  };
}

export const StandaloneQuotebox: StandaloneComponent<StandaloneQuoteboxProps> = ({
  author,
  quote,
  quotationMark,
  options,
  ...props
}) => {
  const { $author: authorProps, $quote: quoteProps, $quotationMark: quotationMarkProps, ...baseProps } = options ?? {};

  return (
    <QuoteBox {...baseProps} {...props}>
      {author && (quotationMark ?? <QuoteBox.QuotationMark {...quotationMarkProps} />)}

      <div>
        <QuoteBox.Quote {...quoteProps}>{quote}</QuoteBox.Quote>
        {author && <QuoteBox.Author {...authorProps}>{author}</QuoteBox.Author>}
      </div>
    </QuoteBox>
  );
};
