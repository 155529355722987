import { GenericSlotFactory } from 'base/components/GenericSlot';
import { BannerTheme } from '@/components/Banner/theme';

const $ = GenericSlotFactory({ theme: BannerTheme });

const Base = $({ as: 'section', slot: 'base' });
const BackgroundImage = $({ as: 'picture', slot: 'backgroundImage' });
const Content = $({ as: 'div', slot: 'content' });

export const Banner = Object.assign(Base, { BackgroundImage, Content });
