/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneLineBreak as ResolvedStandaloneLineBreak } from 'base/components/standalone/LineBreak';

export const LineBreak: typeof ResolvedStandaloneLineBreak = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneLineBreak {...props} />
        </ErrorBoundary>
    );
});

export type LineBreakProps = PropsFromComponent<typeof LineBreak>;

/** @deprecated Use slot architecture instead */
export const LineBreakWith = (extras: DynamicStandaloneExtras): typeof LineBreak => {
    return function LineBreak(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'LineBreak');
        return <ResolvedStandaloneLineBreak {...mergeProps({ options: { theme } }, props)} />;
    }
}