/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorParagraph as ResolvedLabradorParagraph } from 'base/components/labrador/Paragraph';

export const LabradorParagraph: typeof ResolvedLabradorParagraph = withLabradorInstanceof((props) => {
    return <ResolvedLabradorParagraph {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorParagraphProps = PropsFromComponent<typeof LabradorParagraph>;
