import { tw } from '@/utils/tw';
import BaseTimerTheme from 'base/components/Timer/theme';

const Timer = tw.theme({
  extend: BaseTimerTheme,
  slots: {
    base: `mx-auto`,
    title: `mb-3 text-center text-headline-xs`,
    group: ``,
    duration: `h-[56px] w-[66px] rounded-xl bg-white !font-bold text-headline-sm md:text-headline-md`,
    durationLabel: `text-headline-2xs`,
    finished: `text-center text-headline-sm md:max-w-[380px] md:text-headline-md lg:max-w-[580px]`,
  },
});

export default Timer;
