import { ImageProps } from '@/components/Image';
import { Content } from '@/types/content';

export const mapLabradorImageToStandaloneImage = (labradorImage: Content): ImageProps => {
  const { data, meta } = labradorImage ?? {};

  return {
    src: data?.imageUrl?.[meta?.device || 'mobile'] || '',
    alt: data?.altText || ' ',
    caption: data?.showCaption && data?.caption,
    byline: data?.byline,
    link: {
      href: data?.url,
      target: data?.linkTarget,
      options: {
        rel: data?.rel,
      },
    },
    isBodytextChild: data.isBodytextChild,
  };
};
