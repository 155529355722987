import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
});

const ShowHero = tw.theme({
  slots: {
    base: `flex flex-col-reverse items-center justify-between gap-4 bg-gradient-to-b from-white to-gray-100 px-4 py-3 md:flex-row md:gap-48 md:p-8`,
    content: `flex w-full flex-col gap-6`,
    headline: `text-headline-lg md:text-headline-2xl`,
    category: 'capitalize',
    button: '',
    description: 'text-body-sm',
    image: 'relative aspect-vertical min-w-[180px] md:min-w-[300px] [&_img]:object-cover',
  },
});

export default Object.assign(ShowHero, { Tag });
