import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Box } from './Box';

export interface StandaloneBoxProps extends StandaloneComponentProps<typeof Box> {
  content?: ReactNode;
}

export const StandaloneBox: StandaloneComponent<StandaloneBoxProps> = ({ content, ...props }) => {
  return <Box {...props}>{content}</Box>;
};
