import { BlipTheme } from '@/components/Blip/theme';
import { IconTheme } from '@/components/Icon/theme';
import { SkeletonTheme } from '@/components/Skeleton/theme';
import { tw } from '@/utils/tw';

const Blip = tw.theme({
  extend: BlipTheme,
  base: `absolute left-4.5 top-5`,
  variants: {
    variant: {
      secondary: `hidden`,
    },
  },
});

const Icon = tw.theme({
  extend: IconTheme,
  base: `absolute right-2.5 top-1/2 z-1 h-4 w-4 -translate-y-1/2 rotate-180`,
});

const Skeleton = tw.theme({
  extend: SkeletonTheme,
  base: ``,
  variants: {
    variant: {
      primary: `h-[73px] md:h-[58px]`,
      secondary: `h-[132px] md:h-[142px]`,
    },
  },
});

const HighlightedArticles = tw.theme({
  slots: {
    base: `bg-gray-100`,
    item: ``,
    headline: `text-black`,
    label: ``,
  },
  variants: {
    variant: {
      primary: {
        base: `flex h-[73px] items-center md:h-[58px]`,
        item: `my-2.5 line-clamp-2 px-9 data-[show-blip=false]:pl-6 md:line-clamp-1`,
        headline: `inline text-headline-sm md:text-headline-md`,
        label: `text-primary-700 mr-1 text-headline-sm after:content-[":"] md:text-headline-md`,
      },

      secondary: {
        base: `h-[132px] md:h-[142px] [&_svg]:hidden`,
        item: `p-3 md:p-6`,
        headline: `mt-1 line-clamp-2 text-headline-md md:mt-3 md:line-clamp-1 md:text-headline-lg`,
        label: `bg-primary-700 inline-block px-1.5 py-1 uppercase text-white text-headline-xs md:px-2.5 md:py-1.5`,
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
