import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Emphasis } from './Emphasis';

export interface StandaloneEmphasisProps extends StandaloneComponentProps<typeof Emphasis> {
  content?: React.ReactNode;
}

export const StandaloneEmphasis: StandaloneComponent<StandaloneEmphasisProps> = ({ content, ...props }) => {
  return <Emphasis {...props}>{content}</Emphasis>;
};
