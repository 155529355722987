import { TimerTheme } from '@/components/Timer/theme';
import { tw } from '@/utils/tw';
import ConfirmationTheme from 'base/components/Confirmation/theme';

const Timer = tw.theme({
  extend: TimerTheme,
  slots: {
    base: `my-8 max-w-[786px] bg-yellow-sea-100 bg-[url(/image/course/confirmation-banner-background.svg)] bg-cover bg-center bg-no-repeat py-6`,
    group: `gap-2 md:gap-3`,
  },
});

const Confirmation = tw.theme({
  extend: ConfirmationTheme,
  slots: {
    base: `px-0 pt-0 sm:px-6`,
    title: `mb-6 text-center text-headline-md md:mb-8 md:text-headline-lg`,
    banner: `mx-auto max-w-[786px] bg-yellow-sea-100 px-4 py-6 md:p-10`,
    bannerHeader: `mb-2 text-headline-sm md:mb-3 md:text-headline-lg`,
    bannerParagraph: `mb-4 text-headline-xs last-of-type:mb-0 md:mb-6 md:text-headline-sm`,
    content: `mb-10 mt-4 text-center text-headline-xs md:text-headline-sm`,
  },
});

export default Object.assign(Confirmation, { Timer });
