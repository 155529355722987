import { useClassName } from '@/styles/Article';
import { ComponentProps, ComponentWithRef } from '@/types/component';
import { Content } from '@/types/content';
import { mergeProps } from '@/utils/mergeProps';
import { renderBySelector } from 'modules/dynamic/components';
import { forwardRef } from 'react';
import { ArticleBodytextComponent } from './Article.Bodytext';
import { ArticleRecommendedComponent } from './Article.Recommended';

export interface ArticleProps extends ComponentProps<'article'> {
  descendants?: Content[];
}

const ArticleComponent: ComponentWithRef<HTMLElement, ArticleProps> = forwardRef(
  ({ children, descendants = [], ...props }, ref) => {
    const className = useClassName('', props);

    return (
      <article data-cts-scroll-depth-item="article" {...mergeProps({ className }, props)} ref={ref}>
        {renderBySelector('lab-dz-pre-header', descendants)}
        {renderBySelector('lab-dz-header', descendants)}
        {renderBySelector('article-post-header', descendants)}
        {children}
        {renderBySelector('article-pre-footer', descendants)}
        {renderBySelector('articleFooter', descendants)}
        {renderBySelector('article-post-footer', descendants)}
      </article>
    );
  },
);

export const Article = Object.assign(ArticleComponent, {
  Bodytext: ArticleBodytextComponent,
  Recommended: ArticleRecommendedComponent,
});
