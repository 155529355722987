import c from '@/styles/InstagramArticlesList';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface InstagramArticlesListHeadlineProps extends ComponentProps<'h1'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default'>;
}

export const InstagramArticlesListHeadlineComponent: Component<InstagramArticlesListHeadlineProps> = ({
  className,
  variant,
  colors,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('headline_variant', variant, 'default');
  const colorClassName = getPropStyles('headline_colors', colors, 'primary');
  const sizeClassName = getPropStyles('headline_size', size, 'default');

  const componentClassName = cn(variantClassName, colorClassName, sizeClassName, className);

  return (
    <h1 className={componentClassName} {...props}>
      {children}
    </h1>
  );
};
