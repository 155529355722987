/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { MabraStandaloneSaveArticleButton as ResolvedStandaloneSaveArticleButton } from 'sites/mabra/components/standalone/SaveArticleButton';

export const SaveArticleButton: typeof ResolvedStandaloneSaveArticleButton = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneSaveArticleButton {...props} />
        </ErrorBoundary>
    );
});

export type SaveArticleButtonProps = PropsFromComponent<typeof SaveArticleButton>;

/** @deprecated Use slot architecture instead */
export const SaveArticleButtonWith = (extras: DynamicStandaloneExtras): typeof SaveArticleButton => {
    return function SaveArticleButton(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'SaveArticleButton');
        return <ResolvedStandaloneSaveArticleButton {...mergeProps({ options: { theme } }, props)} />;
    }
}