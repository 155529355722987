import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseTagsIndexTheme from 'base/components/TagsIndex/theme';

const Tag = tw.theme({
  extend: TagTheme,
  base: ``,
  variants: {
    variant: {
      text: ``,
    },
    size: {
      text: `text-body-sm`,
    },
    colors: {
      text: `text-black`,
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  extend: BaseTagsIndexTheme,
  slots: {
    base: `mb-6`,
    headline: `mb-0 pb-2 text-headline md:pb-3`,
    group: ``,
    letter: `mb-3 font-bold text-headline-lg md:text-headline-lg`,
    tags: ``,
  },
});

export default Object.assign(TagsIndex, { Tag });
