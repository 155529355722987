/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneDirekt as ResolvedStandaloneDirekt } from 'base/components/standalone/Direkt';

export const Direkt: typeof ResolvedStandaloneDirekt = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneDirekt {...props} />
        </ErrorBoundary>
    );
});

export type DirektProps = PropsFromComponent<typeof Direkt>;

/** @deprecated Use slot architecture instead */
export const DirektWith = (extras: DynamicStandaloneExtras): typeof Direkt => {
    return function Direkt(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Direkt');
        return <ResolvedStandaloneDirekt {...mergeProps({ options: { theme } }, props)} />;
    }
}