import c from '@/styles/Youplay';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';

export interface YouplayNotFoundProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

export const YouplayNotFoundComponent: Component<YouplayNotFoundProps> = ({
  variant,
  className,
  colors,
  size,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);
  const variantClassName = getPropStyles('notFound_variant', variant, 'default');
  const colorsClassName = getPropStyles('notFound_colors', colors, 'default');
  const sizeClassName = getPropStyles('notFound_size', size, 'default');

  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);
  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};
