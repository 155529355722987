/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTextTitle as ResolvedStandaloneTextTitle } from 'base/components/standalone/TextTitle';

export const TextTitle: typeof ResolvedStandaloneTextTitle = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTextTitle {...props} />
        </ErrorBoundary>
    );
});

export type TextTitleProps = PropsFromComponent<typeof TextTitle>;

/** @deprecated Use slot architecture instead */
export const TextTitleWith = (extras: DynamicStandaloneExtras): typeof TextTitle => {
    return function TextTitle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextTitle');
        return <ResolvedStandaloneTextTitle {...mergeProps({ options: { theme } }, props)} />;
    }
}