import { Link } from '@/components/Link';
import { useStableOptions, useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { isReactNode } from '@/types/is';
import { AccessLevel } from '@/utils/parseAccessLevel';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import Tracking from 'base/components/TrackingComponent';
import { useMemo } from 'react';
import { ContentLock } from './ContentLock';

export interface StandaloneContentLockProps extends StandaloneComponentProps<typeof ContentLock> {
  accessLevel?: AccessLevel;
  content?: {
    login?:
      | React.ReactNode
      | {
          button?: React.ReactNode;
          caption?: React.ReactNode;
          footer?: React.ReactNode;
          headline?: React.ReactNode;
        };
    purchase?: React.ReactNode;
  };
  lockedUntil?: number;
}

export const StandaloneContentLock: StandaloneComponent<StandaloneContentLockProps> = ({
  accessLevel,
  content,
  lockedUntil,
  options,
  ...props
}) => {
  const { $login: loginOptions, $purchase, ...$base } = useStableOptions(options);
  const {
    $button: $loginButton,
    $caption: $loginCaption,
    $footer: $loginFooter,
    $headline: $loginHeadline,
    ...$login
  } = useStableOptions(loginOptions);

  const { logInUrl, signUpUrl } = useUserAuth();

  const loginButton = useMemo(
    () => (
      <ContentLock.Login.Button
        link={{ href: logInUrl, options: { rel: 'nofollow' } }}
        options={{
          size: 'large',
          className: 'cts-tracking-object',
          ...withTrackingAttributes({
            category: 'signup_box',
            name: 'article_locked_login_button',
            url: logInUrl,
          }),
          ...$loginButton,
        }}
        content={isReactNode(content?.login) || !content?.login?.button ? 'Logga in' : content?.login?.button}
      />
    ),
    [$loginButton, content?.login, logInUrl],
  );

  const stableLoginProps = useStableProps(
    {
      ...withTrackingAttributes({
        category: 'signup_box',
        name: 'article_locked',
      }),
    },
    $login,
  );

  const stableLoginFooterProps = useStableProps(
    {
      className: 'cts-tracking-object',
      ...withTrackingAttributes({
        category: 'signup_box',
        name: 'article_locked_signup_button',
        url: signUpUrl,
      }),
    },
    $loginFooter,
  );

  if (accessLevel === 'open') {
    return null;
  }

  return (
    <ContentLock {...$base} {...props}>
      {accessLevel === 'login' && (
        <Tracking options={{ wrapChildren: true }}>
          <ContentLock.Login {...stableLoginProps}>
            {isReactNode(content?.login) ? (
              content?.login
            ) : (
              <>
                <ContentLock.Login.Headline {...$loginHeadline}>
                  {content?.login?.headline ?? 'Vill du läsa resten av den här artikeln?'}
                </ContentLock.Login.Headline>
                <ContentLock.Login.Caption {...$loginCaption}>
                  {content?.login?.caption ??
                    'För att få ut det mesta av din läsupplevelse, skapa ett konto och logga in — det är helt gratis!'}
                </ContentLock.Login.Caption>
                {loginButton}
                <ContentLock.Login.Footer {...stableLoginFooterProps}>
                  {content?.login?.footer ?? (
                    <Link href={signUpUrl} options={{ rel: 'nofollow' }} content="Eller skapa ditt konto gratis idag" />
                  )}
                </ContentLock.Login.Footer>
              </>
            )}
          </ContentLock.Login>
        </Tracking>
      )}

      {accessLevel === 'purchase' && <ContentLock.Purchase {...$purchase}>{content?.purchase}</ContentLock.Purchase>}
    </ContentLock>
  );
};
