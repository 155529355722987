import { tw } from '@/utils/tw';
import BaseHighlightedArticlesTheme from 'base/components/HighlightedArticles/theme';

const Blip = tw.theme({
  extend: BaseHighlightedArticlesTheme.Blip,
});

const Icon = tw.theme({
  extend: BaseHighlightedArticlesTheme.Icon,
});

const Skeleton = tw.theme({
  extend: BaseHighlightedArticlesTheme.Skeleton,
  base: `h-[70px] md:h-[52px]`,
});

const HighlightedArticles = tw.theme({
  extend: BaseHighlightedArticlesTheme,
  variants: {
    variant: {
      primary: {
        base: `h-[70px] bg-white md:h-[52px]`,
        headline: `text-headline-xs md:text-headline-sm`,
        label: `text-cherry-blossom-500 text-headline-xs md:text-headline-sm`,
      },
    },
  },
});

export default Object.assign(HighlightedArticles, { Icon, Blip, Skeleton });
