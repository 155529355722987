/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { MabraStandaloneBadge as ResolvedStandaloneBadge } from 'sites/mabra/components/standalone/Badge';

export const Badge: typeof ResolvedStandaloneBadge = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneBadge {...props} />
        </ErrorBoundary>
    );
});

export type BadgeProps = PropsFromComponent<typeof Badge>;

/** @deprecated Use slot architecture instead */
export const BadgeWith = (extras: DynamicStandaloneExtras): typeof Badge => {
    return function Badge(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Badge');
        return <ResolvedStandaloneBadge {...mergeProps({ options: { theme } }, props)} />;
    }
}