import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import dayjs from 'dayjs';
import dayjsSvLocale from 'dayjs/locale/sv';
import { ArticleDate } from './ArticleDate';

export interface StandaloneArticleDateProps extends StandaloneComponentProps {
  publishedDatetime?: string;
  updatedDatetime?: string;
  format?: string;
}

export const StandaloneArticleDate: StandaloneComponent<StandaloneArticleDateProps> = ({
  publishedDatetime,
  updatedDatetime,
  format = 'D MMMM YYYY',
  ...props
}) => {
  const formattedPublishDate = dayjs(publishedDatetime).locale(dayjsSvLocale).format(format);
  const formattedUpdateDate = dayjs(updatedDatetime).locale(dayjsSvLocale).format(format);

  return (
    <ArticleDate {...props}>
      <ArticleDate.Date suppressHydrationWarning>{formattedPublishDate}</ArticleDate.Date>

      {updatedDatetime && (
        <ArticleDate.UpdatedDate suppressHydrationWarning>
          &#160;{`UPPDATERAD ${formattedUpdateDate}`}
        </ArticleDate.UpdatedDate>
      )}
    </ArticleDate>
  );
};
