/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { MabraLabradorShowsSlider as ResolvedLabradorShowsSlider } from 'sites/mabra/components/labrador/ShowsSlider';

export const LabradorShowsSlider: typeof ResolvedLabradorShowsSlider = withLabradorInstanceof((props) => {
    return <ResolvedLabradorShowsSlider {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorShowsSliderProps = PropsFromComponent<typeof LabradorShowsSlider>;
