import { useClassName } from '@/styles/ShowsSlider';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface ShowsSliderTitleProps extends ComponentProps<'h3'> {}

export const ShowsSliderTitleComponent: Component<ShowsSliderTitleProps> = ({ children, ...props }) => {
  const className = useClassName('title', props);

  return <h3 {...mergeProps({ className }, props)}>{children}</h3>;
};
