import { LabradorComponent } from '@/types/component';
import { LabradorCustomLinks } from 'base/components/labrador/CustomLinks';

export const MabraLabradorCustomLinks: LabradorComponent = (props) => {
  const isCourseFilter = props.meta.parentRowType === 'courseFilter';

  return (
    <LabradorCustomLinks
      {...props}
      options={{
        colors: isCourseFilter ? 'alternative' : 'default',
        size: isCourseFilter ? 'small' : 'large',
      }}
    />
  );
};
