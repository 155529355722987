import { ContentLock } from '@/components/ContentLock';
import { LabradorComponent } from '@/types/component';
import { parseAccessLevel } from '@/utils/parseAccessLevel';

export const LabradorContentLock: LabradorComponent = ({ type, meta }) => {
  const accessLevel = parseAccessLevel(meta?.accessLevel);
  const lockedUntil = meta?.lockedUntil as number | undefined;

  return <ContentLock {...{ accessLevel, lockedUntil }} />;
};
