/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "notFound_variant_default": "relative flex justify-center items-center mx-auto text-center",
  "notFound_colors_default": "border border-solid border-black",
  "notFound_size_default": "h-[100px] sm:h-[200px]",
  "text_variant_default": "",
  "variant_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Youplay");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;