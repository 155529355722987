/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorBlogSlider as ResolvedLabradorBlogSlider } from 'base/components/labrador/BlogSlider';

export const LabradorBlogSlider: typeof ResolvedLabradorBlogSlider = withLabradorInstanceof((props) => {
    return <ResolvedLabradorBlogSlider {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorBlogSliderProps = PropsFromComponent<typeof LabradorBlogSlider>;
