import { ArticleFooterTheme } from '@/components/ArticleFooter/theme';
import { Tag as StandaloneTag } from '@/components/Tag';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticleFooterTheme });

const Base = $({ as: 'div', slot: 'base' });
const Line = $({ as: 'div', slot: 'line' });
const Tags = $({ as: 'div', slot: 'tags' });
const Tag = GenericSlot({ as: StandaloneTag, theme: ArticleFooterTheme.Tag });

export const ArticleFooter = Object.assign(Base, {
  Line,
  Tags,
  Tag,
});
