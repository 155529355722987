import { AffiliateGallery } from '@/components/AffiliateGallery';
import { useStable } from '@/hooks/useStable';
import { LabradorComponent } from '@/types/component';

export const LabradorAffiliateGallery: LabradorComponent = ({ type, data, meta }) => {
  const {
    active_module,
    product_module_disclaimer,
    native_module_disclaimer,
    product_module_title,
    native_module_title,
    products,
    natives,
  } = useStable(data.gallery);

  if (!products?.length && !natives?.length) {
    return null;
  }

  return (
    <AffiliateGallery
      disclaimer={active_module === 'product' ? product_module_disclaimer : native_module_disclaimer}
      title={active_module === 'product' ? product_module_title : native_module_title}
      products={active_module === 'product' ? products : natives}
      data-content-index={meta.contentIndex}
    />
  );
};
