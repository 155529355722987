export const getChildrenCount = (descendants: any[]) =>
  descendants?.reduce((result, current) => {
    if (['box', 'columns', 'row'].includes(current.type)) {
      result += getChildrenCount(current.children);
    } else {
      result += current?.children?.length || 1;
    }

    return result;
  }, 0);
