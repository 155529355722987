function pascalCase(str: string) {
  if (!str) {
    return '';
  }

  const words = str.split(/[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]+/);
  const newWords = [];
  for (let i = 0; i < words.length; i++) {
    const currentWord = words[i];
    if (currentWord === '') continue;
    newWords.push(currentWord[0].toUpperCase() + currentWord.slice(1));
  }
  return newWords.join('');
}

export function typeToComponent(type: string) {
  return pascalCase(type);
}

export function pathToComponent(path: string) {
  const pathFilter = ['dropZone', 'row', 'columns'];
  return typeToComponent(
    path
      .split('/')
      .splice(1)
      .filter((part: string) => !pathFilter.includes(part))
      .join('-'),
  );
}
