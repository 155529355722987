import { FactBoxTheme } from '@/components/FactBox/theme';
import { Prose as StandaloneProse } from '@/components/Prose';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: FactBoxTheme });

const Base = $({ as: 'section', slot: 'base' });
const Headline = $({
  as: 'h2',
  slot: 'headline',
  render: ({ element }) => <div className="not-prose">{element}</div>,
});
const Prose = $({ as: StandaloneProse, theme: FactBoxTheme.Prose });

export const FactBox = Object.assign(Base, {
  Headline,
  Prose,
});
