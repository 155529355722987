import { tw } from '@/utils/tw';
import DialogTheme from 'base/components/Dialog/theme';

const { CloseIcon, DialogIcon } = DialogTheme;

const CloseIconTheme = tw.theme({
  extend: CloseIcon,
  slots: {
    base: ['fill-black'],
  },
});

const Dialog = tw.theme({
  extend: DialogTheme,
  slots: {
    base: ['bg-gray-50'],
  },
});

export default Object.assign(Dialog, { CloseIcon: CloseIconTheme, DialogIcon });
