import { AffiliateItem } from '@/components/AffiliateItem';
import { LabradorComponent } from '@/types/component';
import { mapLabradorImageToStandaloneImage } from '@/utils/image';

import type { AffiliateItemProduct } from 'base/components/AffiliateItem';
import { findAllOfType } from 'lib/labrador/content-operations/findBy';

export const LabradorAffiliateItem: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const labradorImages = findAllOfType(descendants, 'labradorImage');

  const products = data.products?.map((product: AffiliateItemProduct, index: number) => ({
    image: mapLabradorImageToStandaloneImage(labradorImages[index]),
    ...product,
  }));

  return (
    <AffiliateItem
      counter={
        meta.affiliateItemPosition &&
        meta.affiliateItemsTotal &&
        `${meta.affiliateItemPosition}/${meta.affiliateItemsTotal}`
      }
      headline={data.title}
      subtitle={data.subtitle}
      description={data.description}
      products={products}
      options={{
        multiple: products.length > 1,
      }}
      {...props}
    />
  );
};
