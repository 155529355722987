import { ClassNameProvider } from '@/styles/ListGrid';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { ListGrid } from './ListGrid';

export interface StandaloneListGridProps extends StandaloneComponentProps<typeof ListGrid> {
  content?: ReactNode[];
  headline?: ReactNode;
  description?: ReactNode;
  inject?: { element: ReactNode; at: number };
}

export const StandaloneListGrid: StandaloneComponent<StandaloneListGridProps> = ({
  content,
  headline,
  description,
  options,
  ...props
}) => {
  const { $description, $headline, $list, ...$base } = options ?? {};
  const { $item, ...$baseList } = $list ?? {};
  return (
    <ClassNameProvider value={$base}>
      <ListGrid {...$base} {...props}>
        {headline && <ListGrid.Headline {...$headline}>{headline}</ListGrid.Headline>}
        {description && <ListGrid.Description {...$description}>{description} </ListGrid.Description>}
        <ListGrid.List {...$baseList}>
          {content?.map((item, ind) => (
            <ListGrid.List.Item key={ind} {...$item}>
              {item}
            </ListGrid.List.Item>
          ))}
        </ListGrid.List>
      </ListGrid>
    </ClassNameProvider>
  );
};
export default StandaloneListGrid;
