import { BlogSlider } from '@/components/BlogSlider';
import { LabradorComponent } from '@/types/component';

export const LabradorBlogSlider: LabradorComponent = ({ type, data, meta }) => {
  return (
    <BlogSlider
      headline={data.title}
      bloggers={data.bloggers}
      options={data.options}
      data-content-index={meta.contentIndex}
    />
  );
};
