export function waitFor(predicate: any, { checkInterval = 50, timeout = 5000 } = {}) {
  let intervalId: any;
  return Promise.race([
    new Promise((_, reject) => timeout && setTimeout(reject, timeout)),
    new Promise((resolve) => {
      intervalId = setInterval(() => {
        const val = predicate();
        if (val) resolve(val);
      }, checkInterval);
    }),
  ]).finally(() => clearInterval(intervalId));
}
