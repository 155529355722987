import { CoverBox } from '@/components/CoverBox';
import { LabradorComponent } from '@/types/component';
import { withBackgroundColor } from '@/utils/withBackgroundColor';
import { convertLinkFormat } from 'base/components/CoverBox/convertLinkFormat';
import { renderContentComponent } from 'modules/dynamic/components';
import { useRouter } from 'next/router';
import getConfig from 'modules/config';
import { mergeProps } from '@/utils/merge';

export const LabradorCoverBox: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  const { asPath } = useRouter();
  const { frontPagePathname } = getConfig('keesingGames');

  return (
    <CoverBox
      headline={data.title}
      markup={convertLinkFormat(data.bodytext)}
      images={descendants.map(renderContentComponent)}
      data-bg-color={withBackgroundColor(meta.backgroundColor)}
      data-content-index={meta.contentIndex}
      {...mergeProps({ options: { center: asPath.startsWith(frontPagePathname) } }, props)}
    />
  );
};
