import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';

const Tag = tw.theme({
  extend: TagTheme,
  base: ``,
  variants: {
    size: {
      text: 'text-body-sm',
    },
    variant: {
      text: ``,
    },
    colors: {
      text: ``,
    },
  },
  defaultVariants: {
    variant: 'text',
    size: 'text',
    colors: 'text',
  },
});

const TagsIndex = tw.theme({
  slots: {
    base: `w-full`,
    headline: `mb-8 border-b border-b-black pb-3 text-black text-headline-md md:text-headline-lg`,
    group: `flex flex-col`,
    letter: `mb-1 mt-5 font-extrabold leading-5.75 text-black text-headline-sm md:text-headline-md`,
    tags: `flex flex-wrap gap-x-6 gap-y-3`,
  },
});

export default Object.assign(TagsIndex, { Tag });
