/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorColumns as ResolvedLabradorColumns } from 'base/components/labrador/Columns';

export const LabradorColumns: typeof ResolvedLabradorColumns = withLabradorInstanceof((props) => {
    return <ResolvedLabradorColumns {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorColumnsProps = PropsFromComponent<typeof LabradorColumns>;
