/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneMorePagesTitle as ResolvedStandaloneMorePagesTitle } from 'base/components/standalone/MorePagesTitle';

export const MorePagesTitle: typeof ResolvedStandaloneMorePagesTitle = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneMorePagesTitle {...props} />
        </ErrorBoundary>
    );
});

export type MorePagesTitleProps = PropsFromComponent<typeof MorePagesTitle>;

/** @deprecated Use slot architecture instead */
export const MorePagesTitleWith = (extras: DynamicStandaloneExtras): typeof MorePagesTitle => {
    return function MorePagesTitle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'MorePagesTitle');
        return <ResolvedStandaloneMorePagesTitle {...mergeProps({ options: { theme } }, props)} />;
    }
}