import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useId } from 'react';
import { RadioButton } from './RadioButton';

export interface StandaloneRadioButtonProps extends StandaloneComponentProps<typeof RadioButton> {
  label: string;
}

export const StandaloneRadioButton: StandaloneComponent<StandaloneRadioButtonProps> = ({ label, ...props }) => {
  const id = useId();

  return (
    <RadioButton {...props}>
      <RadioButton.Input id={id} type="radio" />
      <RadioButton.Label htmlFor={id}>{label}</RadioButton.Label>
    </RadioButton>
  );
};
