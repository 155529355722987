import { Blip as StandaloneBlip } from '@/components/Blip';
import { HighlightedArticlesTheme } from '@/components/HighlightedArticles/theme';
import { Icon as StandaloneIcon } from '@/components/Icon';
import { Skeleton as StandaloneSkeleton } from '@/components/Skeleton';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: HighlightedArticlesTheme });

const Base = $({ as: 'section', slot: 'base' });
const Item = $({ as: 'div', slot: 'item' });
const Headline = $({ as: 'h3', slot: 'headline' });
const Label = $({ as: 'span', slot: 'label' });
const Icon = $({ as: StandaloneIcon, theme: HighlightedArticlesTheme.Icon });
const Blip = $({ as: StandaloneBlip, theme: HighlightedArticlesTheme.Blip });
const Skeleton = $({ as: StandaloneSkeleton, theme: HighlightedArticlesTheme.Skeleton });

export const HighlightedArticles = Object.assign(Base, {
  Item,
  Blip,
  Headline,
  Label,
  Icon,
  Skeleton,
});
