import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Columns } from './Columns';

export interface StandaloneColumnsProps extends StandaloneComponentProps<typeof Columns> {
  content?: ReactNode;
}

export const StandaloneColumns: StandaloneComponent<StandaloneColumnsProps> = ({ content, ...props }) => {
  return <Columns {...props}>{content}</Columns>;
};
