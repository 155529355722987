/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTextArea as ResolvedStandaloneTextArea } from 'base/components/standalone/TextArea';

export const TextArea: typeof ResolvedStandaloneTextArea = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTextArea {...props} />
        </ErrorBoundary>
    );
});

export type TextAreaProps = PropsFromComponent<typeof TextArea>;

/** @deprecated Use slot architecture instead */
export const TextAreaWith = (extras: DynamicStandaloneExtras): typeof TextArea => {
    return function TextArea(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextArea');
        return <ResolvedStandaloneTextArea {...mergeProps({ options: { theme } }, props)} />;
    }
}