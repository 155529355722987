import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { useAppState } from 'lib/hooks/useAppState';
import getConfig from 'modules/config';
import { Strossle } from './Strossle';

export interface StandaloneStrossleProps extends StandaloneComponentProps<typeof Strossle> {}

export const StandaloneStrossle: StandaloneComponent<StandaloneStrossleProps> = (props) => {
  const [{ affiliate }] = useAppState();
  const { enabled, id } = getConfig('strossle') || {};

  if (!enabled || !id) return null;

  return <Strossle data-affiliate={affiliate} {...props} />;
};
