/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorYouplay as ResolvedLabradorYouplay } from 'base/components/labrador/Youplay';

export const LabradorYouplay: typeof ResolvedLabradorYouplay = withLabradorInstanceof((props) => {
    return <ResolvedLabradorYouplay {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorYouplayProps = PropsFromComponent<typeof LabradorYouplay>;
