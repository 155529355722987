/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorFacebook as ResolvedLabradorFacebook } from 'base/components/labrador/Facebook';

export const LabradorFacebook: typeof ResolvedLabradorFacebook = withLabradorInstanceof((props) => {
    return <ResolvedLabradorFacebook {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorFacebookProps = PropsFromComponent<typeof LabradorFacebook>;
