/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTimer as ResolvedStandaloneTimer } from 'base/components/standalone/Timer';

export const Timer: typeof ResolvedStandaloneTimer = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTimer {...props} />
        </ErrorBoundary>
    );
});

export type TimerProps = PropsFromComponent<typeof Timer>;

/** @deprecated Use slot architecture instead */
export const TimerWith = (extras: DynamicStandaloneExtras): typeof Timer => {
    return function Timer(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Timer');
        return <ResolvedStandaloneTimer {...mergeProps({ options: { theme } }, props)} />;
    }
}