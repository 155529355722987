/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneVideoReels as ResolvedStandaloneVideoReels } from 'base/components/standalone/VideoReels';

export const VideoReels: typeof ResolvedStandaloneVideoReels = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneVideoReels {...props} />
        </ErrorBoundary>
    );
});

export type VideoReelsProps = PropsFromComponent<typeof VideoReels>;

/** @deprecated Use slot architecture instead */
export const VideoReelsWith = (extras: DynamicStandaloneExtras): typeof VideoReels => {
    return function VideoReels(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'VideoReels');
        return <ResolvedStandaloneVideoReels {...mergeProps({ options: { theme } }, props)} />;
    }
}