/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorCheckbox as ResolvedLabradorCheckbox } from 'base/components/labrador/Checkbox';

export const LabradorCheckbox: typeof ResolvedLabradorCheckbox = withLabradorInstanceof((props) => {
    return <ResolvedLabradorCheckbox {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorCheckboxProps = PropsFromComponent<typeof LabradorCheckbox>;
