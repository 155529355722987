import { Icon, IconProps } from '@/components/Icon';
import { propClassName } from '@/styles/AuthorsList';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface AuthorsListItemIconProps extends ExtendedStandaloneComponentProps<IconProps> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const AuthorsListItemIconComponent: ExtendedStandaloneComponent<AuthorsListItemIconProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('item_icon_colors', colors);
  const sizeClassName = propClassName('item_icon_size', size);
  const variantClassName = propClassName('item_icon_variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <Icon
      options={{ size: 'large', className: componentClassName, ...standaloneOptions }}
      {...standaloneProps}
      {...props}
    />
  );
};
