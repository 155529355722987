import { SavedArticles } from '@/components/SavedArticles';
import { LabradorComponent } from '@/types/component';

export const LabradorSavedArticles: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  return (
    <SavedArticles
      headline={data.headline}
      icon={data.icon}
      button={data.button}
      articles={data.articles || []}
      initialArticlesCount={data.initialArticlesCount}
      {...props}
    />
  );
};
