import { Icon } from '@/components/Icon';
import type { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import type { ReactNode } from 'react';
import { Loader } from './Loader';

export interface StandaloneLoaderProps extends StandaloneComponentProps {
  content?: ReactNode;
}

export const StandaloneLoader: StandaloneComponent<StandaloneLoaderProps> = ({ content, ...props }) => {
  return <Loader {...props}>{content || <Icon name="add" options={{ size: 'extraLarge' }} />}</Loader>;
};
