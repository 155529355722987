import { VideoReels } from '@/components/VideoReels';
import { LabradorComponent } from '@/types/component';

export const LabradorVideoReels: LabradorComponent = ({ data }) => {
  const { placementID } = data ?? {};
  if (!placementID) {
    return null;
  }

  const [reelID, reelName = ''] = placementID.split('%%');
  return <VideoReels placementId={reelID?.trim()} heading={reelName.trim()} />;
};
