/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorTiktok as ResolvedLabradorTiktok } from 'base/components/labrador/Tiktok';

export const LabradorTiktok: typeof ResolvedLabradorTiktok = withLabradorInstanceof((props) => {
    return <ResolvedLabradorTiktok {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorTiktokProps = PropsFromComponent<typeof LabradorTiktok>;
