import { JwVideoTeaser } from '@/components/JwVideo/Teaser';
import { componentTheme } from '@/styles/JwVideoTeaser';
import { LabradorComponent } from '@/types/component';
import { mergeOptions } from '@/utils/merge';

export const LabradorJwVideoTeaser: LabradorComponent = ({ type, meta, data }) => {
  const { videoID, thumbnail, isFrontPage, teaserLink, title, hintText, stillImage, playlist, prominentTag } = data;
  const { teaserIndex } = meta;

  const brand = playlist?.brand ?? playlist?.[0]?.brand;

  const isPriority = teaserIndex <= 10;

  const componentProps = {
    mediaId: videoID,
    teaserLink,
    thumbnail,
    title,
    hintText,
    isStillImage: stillImage,
    tag: prominentTag,
    'data-index': teaserIndex,
    headline: data.headline ?? (!isFrontPage && `VIDEOS FRÅN ${brand?.name ?? brand}`),
    options: mergeOptions(
      isFrontPage
        ? {
            theme: componentTheme('front_page'),
            variant: 'vertical',
            $content: {
              colors: 'default',
              $tag: {
                theme: componentTheme('tag'),
              },
            },
            $media: {
              $image: {
                priority: isPriority,
              },
              $video: {
                preload: isPriority ? 'auto' : 'none',
              },
            },
          }
        : {
            variant: 'horizontal',
            $content: {
              colors: 'secondary',
              $tag: {
                theme: componentTheme('tag'),
              },
            },
          },
      data.options,
    ),
  };

  return <JwVideoTeaser {...componentProps} />;
};
