import { TimerTheme } from '@/components/Timer/theme';
import { tw } from '@/utils/tw';

const Timer = tw.theme({
  extend: TimerTheme,
});

const CourseCountdown = tw.theme({
  slots: {
    base: ``,
    logo: ``,
    description: ``,
  },
});

export default Object.assign(CourseCountdown, { Timer });
