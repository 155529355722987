import { LabradorComponent } from '@/types/component';
import { renderContentComponent } from 'modules/dynamic/components';
import { Fragment } from 'react';

export const LabradorBodytext: LabradorComponent = ({ descendants }) => {
  return (
    <>
      {descendants.map((child, index) => {
        return <Fragment key={index}>{renderContentComponent(child)}</Fragment>;
      })}
    </>
  );
};
