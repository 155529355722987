import { TextTitle } from '@/components/TextTitle';
import { LabradorComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getLabStyle } from '@/utils/labStyle';
import { withBackgroundColor } from '@/utils/withBackgroundColor';
import { withTextColor } from '@/utils/withTextColor';

export const LabradorTextTitle: LabradorComponent = ({ type, meta, data, descendants, ...props }) => {
  const style = getLabStyle(data?.fieldSettings?.title);
  const textColor = withTextColor(data?.fieldSettings?.title?.textColor);
  const backgroundColor = withBackgroundColor(data?.fieldSettings?.title?.textBackgroundColor);

  return (
    <TextTitle content={data.title} options={{ className: cn('lab', textColor, backgroundColor), style }} {...props} />
  );
};
