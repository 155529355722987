import { LabradorComponents } from '@/components/labrador';
import { LabradorFallback } from '@/components/labrador/Fallback';
import { capitalize } from 'lodash-es';
import React from 'react';
import { Content, ContentProps } from 'types/content';

const LabradorComponentsDictionary = Object.keys(LabradorComponents).reduce(
  (dictionary, key) => {
    dictionary[key.toLowerCase()] = key as keyof typeof LabradorComponents;
    return dictionary;
  },
  {} as Record<string, keyof typeof LabradorComponents>,
);

function component<T = ContentProps>(name: string) {
  const key = LabradorComponentsDictionary[name.toLowerCase()];
  const component = LabradorComponents[key] as React.FunctionComponent<T>;

  if (!component) {
    return LabradorFallback;
  }

  return component;
}

function renderComponent(data: Content) {
  const key = data.type.replace(/^labrador/, '');
  const Component = component(key);

  return <Component {...bindProps(data)} />;
}

export function componentExists(name: string) {
  const component = LabradorComponents[capitalize(name) as keyof typeof LabradorComponents];

  return component !== undefined;
}

export function bindProps(contentData: Content): ContentProps {
  return {
    type: contentData.type,
    data: contentData.data,
    meta: contentData.meta,
    descendants: contentData.children,
  };
}

export function renderContentComponent(contentData: Content) {
  return renderComponent(contentData);
}

export function renderPostTypeComponent(postData: Content) {
  return renderComponent(postData);
}

export function renderBySelector(selector: string, children?: Content[]): React.JSX.Element | undefined {
  const componentData = children?.find((child) => child.meta.selector === selector);

  if (componentData) return renderComponent(componentData);
}
