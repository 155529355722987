/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorAffiliateGallery as ResolvedLabradorAffiliateGallery } from 'base/components/labrador/AffiliateGallery';

export const LabradorAffiliateGallery: typeof ResolvedLabradorAffiliateGallery = withLabradorInstanceof((props) => {
    return <ResolvedLabradorAffiliateGallery {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorAffiliateGalleryProps = PropsFromComponent<typeof LabradorAffiliateGallery>;
