/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneHeading as ResolvedStandaloneHeading } from 'base/components/standalone/Heading';

export const Heading: typeof ResolvedStandaloneHeading = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneHeading {...props} />
        </ErrorBoundary>
    );
});

export type HeadingProps = PropsFromComponent<typeof Heading>;

/** @deprecated Use slot architecture instead */
export const HeadingWith = (extras: DynamicStandaloneExtras): typeof Heading => {
    return function Heading(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Heading');
        return <ResolvedStandaloneHeading {...mergeProps({ options: { theme } }, props)} />;
    }
}