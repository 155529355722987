import { BylineTheme } from '@/components/Byline/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: BylineTheme });

const Base = $({ as: 'div', slot: 'base' });
const Author = $({ as: 'span', slot: 'author' });
const Text = $({ as: 'span', slot: 'text' });
const Image = $({
  as: StandaloneImage,
  theme: BylineTheme.Image,
});

export const Byline = Object.assign(Base, {
  Author,
  Text,
  Image,
});
