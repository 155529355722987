import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Youtube, YoutubeProps } from './Youtube';

export interface StandaloneYoutubeProps extends StandaloneComponentProps {
  vidID: string;
  options?: Omit<YoutubeProps, 'vidID'>;
}

export const StandaloneYoutube: StandaloneComponent<StandaloneYoutubeProps> = ({ options, ...props }) => {
  return <Youtube {...options} {...props} />;
};
