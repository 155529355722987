import { tw } from '@/utils/tw';

const LoopAround = tw.theme({
  slots: {
    base: `flex flex-row flex-nowrap overflow-hidden whitespace-nowrap bg-primary-700 py-1 text-primary-100`,
    reel: `animate-loop uppercase italic text-headline-xs`,
  },
  variants: {
    variant: {
      primary: {},
      secondary: {},
    },
  },
});

export default LoopAround;
