import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Instagram, InstagramProps } from './Instagram';

export interface StandaloneInstagramProps extends StandaloneComponentProps {
  instagramHtml: string;
  options?: InstagramProps;
}

export const StandaloneInstagram: StandaloneComponent<StandaloneInstagramProps> = ({ options, ...props }) => {
  return <Instagram {...options} {...props} />;
};
