import { ShowHeroTheme } from '@/components/ShowHero/theme';
import { Tag as StandaloneTag } from '@/components/Tag';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ShowHeroTheme });

const Base = $({ as: 'section', slot: 'base' });
const Content = $({ as: 'div', slot: 'content' });
const Headline = $({ as: 'h1', slot: 'headline' });
const Category = $({ as: 'span', slot: 'category' });
const Button = $({ as: 'span', slot: 'button' });
const Description = $({ as: 'p', slot: 'description' });
const Tag = GenericSlot({ as: StandaloneTag, theme: ShowHeroTheme.Tag });
const Image = $({ as: 'div', slot: 'image' });

export const ShowHero = Object.assign(Base, {
  Content,
  Headline,
  Category,
  Button,
  Description,
  Image,
  Tag,
});
