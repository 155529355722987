import { tw } from '@/utils/tw';
import BaseSavedArticlesTheme from 'base/components/SavedArticles/theme';

const Tag = tw.theme({
  extend: BaseSavedArticlesTheme.Tag,
  variants: {
    size: { text: `text-preheadline` },
    variant: { text: `capitalize` },
  },
});

const Icon = tw.theme({
  base: `text-gray-400 hover:text-gray-600`,
  extend: BaseSavedArticlesTheme.Icon,
});

const Image = tw.theme({
  extend: BaseSavedArticlesTheme.Image,
});

const SavedArticles = tw.theme({
  extend: BaseSavedArticlesTheme,
  slots: {
    headline: `text-headline-sm after:hidden`,
    empty: `col-span-4 mt-3 gap-3 text-preheadline`,
    articleCaption: `pt-1.5 text-headline-sm md:text-headline-sm`,
    articleDescription: `line-clamp-3 pt-1.5 text-headline-xs sm:pt-2 md:text-headline-xs`,
  },
});

export default Object.assign(SavedArticles, { Tag, Icon, Image });
