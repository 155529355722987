/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorImage as ResolvedLabradorImage } from 'base/components/labrador/Image';

export const LabradorImage: typeof ResolvedLabradorImage = withLabradorInstanceof((props) => {
    return <ResolvedLabradorImage {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorImageProps = PropsFromComponent<typeof LabradorImage>;
