import { tw } from '@/utils/tw';

const Loader = tw.theme({
  slots: {
    base: `relative mx-auto flex h-16 w-16 items-center justify-center`,
  },
  variants: {
    animation: {
      default: {
        base: `animate-spin animate-ease-in-out`,
      },
      alternative: {
        base: `animate-pulse`,
      },
    },
  },
  defaultVariants: {
    animation: 'default',
  },
});

export default Loader;
