/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { MabraLabradorCourseDays as ResolvedLabradorCourseDays } from 'sites/mabra/components/labrador/Course/Days';

export const LabradorCourseDays: typeof ResolvedLabradorCourseDays = withLabradorInstanceof((props) => {
    return <ResolvedLabradorCourseDays {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorCourseDaysProps = PropsFromComponent<typeof LabradorCourseDays>;
