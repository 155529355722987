import { http } from '@/utils/http';
import { removeTrailingSlash } from '@/utils/removeTrailingSlash';

export const fetchArticlesData = async (ids: string | string[]) => {
  const articleIds = Array.isArray(ids) ? ids : [ids];

  const labradorApiParams = {
    query: `id:( ${articleIds.join(' OR ')}  )`,
  };

  const opts = {
    params: labradorApiParams,
    auth: {
      username: process.env.OCELOT_LABRADOR_BASIC_AUTH_USERNAME || '',
      password: process.env.OCELOT_LABRADOR_BASIC_AUTH_PASSWORD || '',
    },
  };

  const site: string = process.env.OCELOT_SITE || '';
  if (!site) throw new Error('Missing env: OCELOT_SITE');

  const apiBaseUrl: string = removeTrailingSlash(process.env.OCELOT_LABRADOR_API || '');
  if (!apiBaseUrl) throw new Error('Missing env: OCELOT_LABRADOR_API');

  const apiEndpoint = '/article';
  const fetchUrl = `${apiBaseUrl}${apiEndpoint}`;

  try {
    const response = await http.get(fetchUrl, opts);
    return response?.data;
  } catch {
    throw new Error('Could not load article');
  }
};
