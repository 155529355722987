import { JwVideoArticle, JwVideoArticleProps } from '@/components/JwVideo/Article';
import { StandaloneComponent } from '@/types/component';
import { Youplay } from './Youplay';
import { YouplayNotFoundProps } from './Youplay.NotFound';
import { YouplayNotFoundTextProps } from './Youplay.NotFound.Text';

export interface StandaloneYouplayProps extends JwVideoArticleProps {
  options?: JwVideoArticleProps['options'] & {
    $notFound?: YouplayNotFoundProps;
    $text?: YouplayNotFoundTextProps;
  };
}

export const StandaloneYouplay: StandaloneComponent<StandaloneYouplayProps> = (props) => {
  const {
    playerSrc,
    showDescription,
    videoPageLink,
    durationMinutes,
    titleTextSize,
    metadata,
    videoData,
    b2bVideo,
    options,
    ...baseProps
  } = props;
  const { $notFound: notFoundProps, $text: textProps, ...videoArticleProps } = options || {};

  return (
    <Youplay {...baseProps}>
      {videoData?.videoId ? (
        <JwVideoArticle
          playerSrc={playerSrc}
          videoData={videoData}
          b2bVideo={b2bVideo}
          showDescription={showDescription}
          durationMinutes={durationMinutes}
          videoPageLink={videoPageLink}
          titleTextSize={titleTextSize}
          metadata={metadata}
          {...videoArticleProps}
        />
      ) : (
        <Youplay.NotFound {...notFoundProps}>
          <Youplay.NotFound.Text {...textProps}>Videon kunde inte hittas</Youplay.NotFound.Text>{' '}
        </Youplay.NotFound>
      )}
    </Youplay>
  );
};
