import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';

const Image = tw.theme({
  extend: ImageTheme,
});

const Byline = tw.theme({
  slots: {
    base: `flex items-center`,
    author: `text-headline-xs`,
    text: `mr-1 text-gray-700 text-headline-xs`,
  },
});

export default Object.assign(Byline, { Image });
