import { ArticleDisclosureLabelTheme } from '@/components/ArticleDisclosureLabel/theme';
import { GenericSlot } from 'base/components/GenericSlot';
import useWindowScrollDirection from 'lib/hooks/useWindowScrollDirection';
import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';

export const ArticleDisclosureLabel = GenericSlot({
  as: 'div',
  theme: ArticleDisclosureLabelTheme,
  render: function Render({ element, props }) {
    const [topOffset, setTopOffset] = useState<number>(0);
    const scrollDirection = useWindowScrollDirection({ initialDirection: 'up' });

    useEffectOnce(() => {
      const menu = document.getElementById('menu');

      if (!menu) return;

      const resizeObserver = new ResizeObserver((entries) => {
        setTopOffset(entries?.[0]?.contentRect?.height ?? 0);
      });

      resizeObserver.observe(menu);

      return () => {
        resizeObserver.unobserve(menu);
      };
    });

    return React.cloneElement(element, {
      'data-scroll-direction': scrollDirection,
      style: {
        top: topOffset,
      },
    } as typeof props);
  },
});
