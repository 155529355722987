import { tw } from '@/utils/tw';

const List = tw.theme({
  slots: {
    base: '',
    item: '',
  },
});

export default List;
