/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorBodytext as ResolvedLabradorBodytext } from 'base/components/labrador/Bodytext';

export const LabradorBodytext: typeof ResolvedLabradorBodytext = withLabradorInstanceof((props) => {
    return <ResolvedLabradorBodytext {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorBodytextProps = PropsFromComponent<typeof LabradorBodytext>;
