/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneYouplay as ResolvedStandaloneYouplay } from 'base/components/standalone/Youplay';

export const Youplay: typeof ResolvedStandaloneYouplay = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneYouplay {...props} />
        </ErrorBoundary>
    );
});

export type YouplayProps = PropsFromComponent<typeof Youplay>;

/** @deprecated Use slot architecture instead */
export const YouplayWith = (extras: DynamicStandaloneExtras): typeof Youplay => {
    return function Youplay(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Youplay');
        return <ResolvedStandaloneYouplay {...mergeProps({ options: { theme } }, props)} />;
    }
}