/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorHeading as ResolvedLabradorHeading } from 'base/components/labrador/Heading';

export const LabradorHeading: typeof ResolvedLabradorHeading = withLabradorInstanceof((props) => {
    return <ResolvedLabradorHeading {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorHeadingProps = PropsFromComponent<typeof LabradorHeading>;
