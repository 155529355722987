/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "button": {
    "size_medium": "px-3 pt-1 pb-1.5 text-headline-xs gap-1.5 after:hidden md:px-6 md:pt-2 md:pb-2.5 md:gap-2.5 md:after:h-0.25 md:after:w-full"
  }
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "CourseCountdown");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;