import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ShoppableVideo, VideoShoppableProps } from './Video.Shoppable';

export interface StandaloneJwVideoShoppableProps extends StandaloneComponentProps {
  mediaId: VideoShoppableProps['mediaId'];
}

export const StandaloneJwVideoShoppable: StandaloneComponent<StandaloneJwVideoShoppableProps> = ({
  mediaId,
  ...props
}) => {
  return <ShoppableVideo mediaId={mediaId} {...props} />;
};
