import { propClassName } from '@/styles/RecommendedArticles';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { RecommendedArticlesHeadlineComponent } from './RecommendedArticles.Headline';
import { RecommendedArticlesListComponent } from './RecommendedArticles.List';
import { RecommendedArticlesListItemComponent } from './RecommendedArticles.List.Item';
import { RecommendedArticlesListItemTeaserComponent } from './RecommendedArticles.List.Item.Teaser';

export interface RecommendedArticlesProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'default'>;
  size?: ClassNameProp<'default'>;
}

const RecommendedArticlesComponent: Component<RecommendedArticlesProps> = ({
  variant,
  children,
  colors,
  size,
  className,
  ...props
}) => {
  const variantClassName = propClassName('variant', variant, 'default');
  const colorsClassName = propClassName('colors', colors, 'default');
  const sizeClassName = propClassName('size', size, 'default');

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};

export const RecommendedArticles = Object.assign(RecommendedArticlesComponent, {
  Headline: RecommendedArticlesHeadlineComponent,
  List: Object.assign(RecommendedArticlesListComponent, {
    Item: Object.assign(RecommendedArticlesListItemComponent, {
      Teaser: RecommendedArticlesListItemTeaserComponent,
    }),
  }),
});
