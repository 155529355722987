import { ButtonProps } from '@/components/Button';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Banner } from './Banner';

export interface StandaloneBannerProps extends StandaloneComponentProps<typeof Banner> {
  content?: ReactNode;
  backgroundImage?: ReactNode;
  link?: ButtonProps['link'];
}

export const StandaloneBanner: StandaloneComponent<StandaloneBannerProps> = ({
  content,
  backgroundImage,
  ...props
}) => {
  return (
    <Banner {...props}>
      {backgroundImage && <Banner.BackgroundImage>{backgroundImage}</Banner.BackgroundImage>}
      <Banner.Content>{content}</Banner.Content>
    </Banner>
  );
};
