/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneScrollPageControls as ResolvedStandaloneScrollPageControls } from 'base/components/standalone/ScrollPageControls';

export const ScrollPageControls: typeof ResolvedStandaloneScrollPageControls = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneScrollPageControls {...props} />
        </ErrorBoundary>
    );
});

export type ScrollPageControlsProps = PropsFromComponent<typeof ScrollPageControls>;

/** @deprecated Use slot architecture instead */
export const ScrollPageControlsWith = (extras: DynamicStandaloneExtras): typeof ScrollPageControls => {
    return function ScrollPageControls(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'ScrollPageControls');
        return <ResolvedStandaloneScrollPageControls {...mergeProps({ options: { theme } }, props)} />;
    }
}