import { FactBox } from '@/components/FactBox';
import { LabradorComponent } from '@/types/component';

import { renderContentComponent } from 'modules/dynamic/components';

export const LabradorFactBox: LabradorComponent = ({ meta, data, descendants }) => {
  const children = descendants
    .filter((child) => {
      const isInvalidImage = child.type === 'labradorImage' && !child.data.imageId;
      return !isInvalidImage;
    })
    .map(renderContentComponent);

  return (
    <FactBox headline={data.title} markup={data.bodytext} data-content-index={meta.contentIndex} elements={children} />
  );
};
