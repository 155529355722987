import { Markup } from '@/components/Markup';
import Script from 'next/script';
import { useMemo } from 'react';
import { Twitter } from './Twitter';

export const StandaloneTwitter: typeof Markup = (props) => {
  const TwitterComponent = useMemo(() => <Twitter {...props} />, []);
  return (
    <>
      <Script src="https://platform.x.com/widgets.js" strategy="lazyOnload" />
      {TwitterComponent}
    </>
  );
};
