import { TagProps } from '@/components/Tag';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ArticleFooter } from './ArticleFooter';

export interface StandaloneArticleFooterProps extends StandaloneComponentProps<typeof ArticleFooter> {
  tags?: TagProps[];
}

export const StandaloneArticleFooter: StandaloneComponent<StandaloneArticleFooterProps> = ({ tags, ...props }) => {
  return (
    <ArticleFooter {...props}>
      <ArticleFooter.Line />
      <ArticleFooter.Tags>
        {tags?.map((tag, index) => (
          <ArticleFooter.Tag key={index} value={tag.value} link={tag.link} options={props.options?.$tag} />
        ))}
      </ArticleFooter.Tags>
    </ArticleFooter>
  );
};
