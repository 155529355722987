/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorBox as ResolvedLabradorBox } from 'base/components/labrador/Box';

export const LabradorBox: typeof ResolvedLabradorBox = withLabradorInstanceof((props) => {
    return <ResolvedLabradorBox {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorBoxProps = PropsFromComponent<typeof LabradorBox>;
