/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneJwVideoShoppable as ResolvedStandaloneJwVideoShoppable } from 'base/components/standalone/JwVideo/Shoppable';

export const JwVideoShoppable: typeof ResolvedStandaloneJwVideoShoppable = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneJwVideoShoppable {...props} />
        </ErrorBoundary>
    );
});

export type JwVideoShoppableProps = PropsFromComponent<typeof JwVideoShoppable>;

/** @deprecated Use slot architecture instead */
export const JwVideoShoppableWith = (extras: DynamicStandaloneExtras): typeof JwVideoShoppable => {
    return function JwVideoShoppable(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'JwVideoShoppable');
        return <ResolvedStandaloneJwVideoShoppable {...mergeProps({ options: { theme } }, props)} />;
    }
}