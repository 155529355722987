import { JwVideoTeaser, JwVideoTeaserProps } from '@/components/JwVideo/Teaser';
import { componentTheme as jwVideoTeaserComponentTheme } from '@/styles/JwVideoTeaser';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions } from '@/utils/merge';
import { VideoShowPlaylistPage } from 'lib/data/video';
import { ReactNode } from 'react';
import { MoreEpisodes } from './MoreEpisodes';

interface ExtendedOptions {
  $teaser?: JwVideoTeaserProps['options'];
}

export interface StandaloneMoreEpisodesProps extends StandaloneComponentProps<typeof MoreEpisodes, ExtendedOptions> {
  headline?: ReactNode;
  episodes?: VideoShowPlaylistPage['relatedMedia'];
}

export const StandaloneMoreEpisodes: StandaloneComponent<StandaloneMoreEpisodesProps> = ({
  episodes,
  headline,
  ...props
}) => {
  return (
    <MoreEpisodes {...props}>
      {headline && <MoreEpisodes.Headline>{headline}</MoreEpisodes.Headline>}
      {episodes && (
        <MoreEpisodes.List
          options={props.options?.$list}
          items={episodes.map((media) => (
            <JwVideoTeaser
              key={media.mediaId}
              mediaId={media.mediaId}
              thumbnail={media.image}
              isStillImage={false}
              title={media.title}
              tag={media.prominentTag}
              teaserLink={media.url}
              options={mergeOptions(
                {
                  theme: jwVideoTeaserComponentTheme('video_pages'),
                  variant: 'horizontal',
                  $media: {
                    $video: {
                      playOnHover: true,
                    },
                  },
                  $link: {
                    target: '_self',
                  },
                  $content: {
                    colors: 'default',
                  },
                },
                props.options?.$teaser,
              )}
            />
          ))}
        />
      )}
    </MoreEpisodes>
  );
};
