import { RecaptchaActions } from './recaptchaActions';

export const getRecaptchaToken = async (action: RecaptchaActions) => {
  const windowObj = window as any;

  return new Promise<string>((resolve) => {
    windowObj.grecaptcha.enterprise.ready(async () => {
      const recaptchaToken = await windowObj.grecaptcha.enterprise.execute(process.env.OCELOT_RECAPTCHA_KEY, {
        action,
      });

      resolve(recaptchaToken);
    });
  });
};
