import { ProseTheme } from '@/components/Prose/theme';
import { tw } from '@/utils/tw';

const Prose = tw.theme({
  extend: ProseTheme,
  base: 'prose-factbox',
});

const FactBox = tw.theme({
  slots: {
    base: 'bg-gray-100 p-3 sm:p-6',
    headline: 'mb-3 uppercase text-primary-700 text-headline-sm',
  },
});

export default Object.assign(FactBox, { Prose });
