import { tw } from '@/utils/tw';
import RadioButtonTheme from 'base/components/RadioButton/theme';

const RadioButton = tw.theme({
  extend: RadioButtonTheme,
  slots: {
    input: [
      'appearance-none',
      'bg-clip-content',
      'border-2',
      'border-gray-300',
      'checked:bg-white',
      'checked:border-7',
      'checked:border-success-500',
      'checked:focus-visible:outline-none',
      'checked:focus:after:absolute',
      'checked:focus:after:block',
      'checked:focus:after:border-2',
      'checked:focus:after:border-success-700',
      'checked:focus:after:h-6',
      'checked:focus:after:left-0',
      'checked:focus:after:rounded-full',
      'checked:focus:after:top-0',
      'checked:focus:after:w-6',
      'checked:focus:bg-white',
      'checked:focus:border-success-500',
      'checked:focus:border-success-500',
      'checked:focus:hover:border-success-500',
      'checked:focus:shadow-success-500',
      'checked:hover:bg-white',
      'checked:hover:border-success-500',
      'focus-visible:outline-none',
      'focus-visible:shadow-success-500',
      'focus:border-7',
      'focus:border-success-500',
      'focus:hover:border-success-500',
      'focus:shadow-success-500',
      'h-6',
      'hover:border-7',
      'hover:border-success-500',
      'rounded-full',
      'w-6',
      `checked:focus:shadow-[0px_0px_8px]`,
      `focus-visible:shadow-[0px_0px_8px]`,
      `focus:shadow-[0px_0px_8px]`,
    ],
    label: ['ml-2.5', 'text-headline-xs'],
  },
});

export default RadioButton;
