import { Tag as StandaloneTag } from '@/components/Tag';
import { TagsIndexTheme } from '@/components/TagsIndex/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TagsIndexTheme });

const Base = $({ as: 'div', slot: 'base' });
const Headline = $({ as: 'h2', slot: 'headline' });
const Group = $({ as: 'div', slot: 'group' });
const Letter = $({ as: 'span', slot: 'letter' });
const Tags = $({ as: 'div', slot: 'tags' });
const Tag = $({ as: StandaloneTag, theme: TagsIndexTheme.Tag });

export const TagsIndex = Object.assign(Base, {
  Headline,
  Group,
  Letter,
  Tags,
  Tag,
});
