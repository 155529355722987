/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorHighlightedArticles as ResolvedLabradorHighlightedArticles } from 'base/components/labrador/HighlightedArticles';

export const LabradorHighlightedArticles: typeof ResolvedLabradorHighlightedArticles = withLabradorInstanceof((props) => {
    return <ResolvedLabradorHighlightedArticles {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorHighlightedArticlesProps = PropsFromComponent<typeof LabradorHighlightedArticles>;
