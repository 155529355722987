import { tw } from '@/utils/tw';

const Slider = tw.theme({
  slots: {
    base: ['w-full'],
    slide: ['group/slide', 'p-6', 'bg-primary-200'],
  },
  variants: {
    aspectRatio: {
      horizontal: {
        slide: ['aspect-video', 'w-40', 'sm:w-54', 'md:w-68'],
      },
      vertical: {
        slide: ['aspect-vertical', 'w-20', 'sm:w-30', 'md:w-40'],
      },
      square: {
        slide: ['aspect-square', 'w-30', 'sm:w-40', 'md:w-50'],
      },
    },
  },
  defaultVariants: {
    aspectRatio: 'horizontal',
  },
});

export default Slider;
