import { ListTheme } from '@/components/List/theme';
import { tw } from '@/utils/tw';

const List = tw.theme({
  extend: ListTheme,
  base: ['flex', 'flex-col', 'gap-4', 'md:gap-6'],
});
const MoreEpisodes = tw.theme({
  slots: {
    base: [],
    headline: ['text-headline-3xs', 'md:text-headline-2xs', 'uppercase'],
  },
});

export default Object.assign(MoreEpisodes, { List });
