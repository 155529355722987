import { useAppState } from '@/hooks/useAppState';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { TextSubtitle } from './TextSubtitle';

export interface StandaloneTextSubtitleProps extends StandaloneComponentProps<typeof TextSubtitle> {
  content: React.ReactNode;
}

export const StandaloneTextSubtitle: StandaloneComponent<StandaloneTextSubtitleProps> = ({ content, ...props }) => {
  const [{ type }] = useAppState();

  return (
    <TextSubtitle data-page-type={type} {...props}>
      {content}
    </TextSubtitle>
  );
};
