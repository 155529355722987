import { useAppState } from '@/hooks/useAppState';
import { LabradorComponent } from '@/types/component';
import { isServer } from '@/utils/isServer';
import { logger } from '@/utils/logger';
import { renderContentComponent } from 'modules/dynamic/components';
import { Fragment } from 'react';

/**
 * This is the content fallback component. It will be rendered whenever we recieve a
 * content type from Labrador that we do not yet have a component created for.
 */
export const LabradorFallback: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ publishedUrl }] = useAppState();

  const knownCustomSelectors = [
    'dropZone',
    'articlePreHeader',
    'articlePostHeader',
    'articlePreFooter',
    'articlePostFooter',
  ];

  if (isServer() && !knownCustomSelectors.includes(type)) {
    logger.warn(`Content box '${type}' is not supported`, { publishedUrl });
  }

  return (
    <>
      {descendants.map((child, index) => {
        return <Fragment key={index}>{renderContentComponent(child)}</Fragment>;
      })}
    </>
  );
};
