import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface NewsletterSignupFieldsetProps extends ComponentProps<'fieldset'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const NewsletterSignupFieldsetComponent: Component<NewsletterSignupFieldsetProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('fieldset_colors', colors, 'primary');
  const sizeClassName = propClassName('fieldset_size', size ?? variant, 'default');
  const variantClassName = propClassName('fieldset_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <fieldset className={componentClassName} {...props}>
      {children}
    </fieldset>
  );
};
