import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import React from 'react';
import { FactBox } from './FactBox';

export interface StandaloneFactBoxProps extends StandaloneComponentProps<typeof FactBox> {
  headline?: React.ReactNode;
  markup?: string;
  elements?: JSX.Element[];
}

export const StandaloneFactBox: StandaloneComponent<StandaloneFactBoxProps> = ({
  headline,
  markup,
  elements,
  ...props
}) => {
  return (
    <FactBox {...props}>
      {headline && <FactBox.Headline>{headline}</FactBox.Headline>}
      {elements?.map((element, index) => <React.Fragment key={index}>{element}</React.Fragment>)}
      {markup && <FactBox.Prose {...{ markup }} />}
    </FactBox>
  );
};

export default StandaloneFactBox;
