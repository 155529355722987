import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { logger } from '@/utils/logger';
import { List } from './List';

export interface StandaloneListProps extends StandaloneComponentProps<typeof List> {
  as?: 'ul' | 'ol';
  items?: React.ReactNode[];
}

export const StandaloneList: StandaloneComponent<StandaloneListProps> = ({ items, ...props }) => {
  if (!Array.isArray(items)) {
    logger.warn("List's `items` property is not an array");
    return null;
  }

  return (
    <List {...props}>
      {items.map((item, index) => (
        <List.Item key={index}>{item}</List.Item>
      ))}
    </List>
  );
};
