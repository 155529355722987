import { Image } from '@/components/Image';
import { LabradorComponent } from '@/types/component';

import { mapLabradorImageToStandaloneImage } from '@/utils/image';

export const LabradorImage: LabradorComponent = ({ type, data, meta, descendants }) => {
  const parent = meta.path.split('/').pop() || '';

  // Caption should never be shown on article teasers
  if (parent === 'articleTeaser') data.showCaption = false;

  if (meta.path.startsWith('article/bodytext')) {
    data.isBodytextChild = true;
  }

  return <Image {...mapLabradorImageToStandaloneImage({ type, data, meta, children: descendants })} />;
};
