import { useAppState } from '@/hooks/useAppState';
import { MabraStandaloneArticleTeaserBodytext } from '@/mabra/components/ArticleTeaser/Bodytext';
import { LabradorComponent } from '@/types/component';

import { findFirstOfType } from 'lib/labrador/content-operations/findBy';

export const MabraLabradorArticleTeaserBodytext: LabradorComponent = ({ type, data, meta, descendants }) => {
  const [{ device }] = useAppState();

  const { badgeData, category, categoryUrl, title, publishedUrl, kicker, footer } = data ?? {};

  const imageSrc = findFirstOfType(descendants, 'labradorImage')?.data?.imageUrl?.[device];

  return (
    <MabraStandaloneArticleTeaserBodytext
      links={{
        article: { href: publishedUrl },
        category: { href: categoryUrl || category },
      }}
      headline={kicker}
      description={title}
      footer={footer}
      badge={badgeData}
      image={imageSrc}
      data-content-index={meta.contentIndex}
    />
  );
};
