/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorArticleTeaser as ResolvedLabradorArticleTeaser } from 'base/components/labrador/ArticleTeaser';

export const LabradorArticleTeaser: typeof ResolvedLabradorArticleTeaser = withLabradorInstanceof((props) => {
    return <ResolvedLabradorArticleTeaser {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorArticleTeaserProps = PropsFromComponent<typeof LabradorArticleTeaser>;
