/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorFront as ResolvedLabradorFront } from 'base/components/labrador/Front';

export const LabradorFront: typeof ResolvedLabradorFront = withLabradorInstanceof((props) => {
    return <ResolvedLabradorFront {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorFrontProps = PropsFromComponent<typeof LabradorFront>;
