import { getRecommendationMicroservice } from '@/utils/getRecommendationMicroservice';
import { getSite } from '@/utils/getSite';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';

export const getMostReadArticlesIds = async ({ excludeIds, limit }: { excludeIds?: number[]; limit?: number } = {}) => {
  const url = getUrl('/mostRead', getRecommendationMicroservice());

  if (!url) {
    return [];
  }

  url?.searchParams.set('brand', getSite());
  excludeIds?.length && url?.searchParams.set('exclude', excludeIds.join(','));
  url?.searchParams.set('limit', `${limit ?? 10}`);

  const response = await http.get<number[]>(url.href);
  const articleIds = response?.data ?? [];
  return articleIds;
};
