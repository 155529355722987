import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  defaultVariants: {
    size: 'medium',
  },
});

const KeesingGames = tw.theme({
  slots: {
    base: [],
    puzzlePortal: [
      '[&_div.mobile-device.mobile_.puzzle-player]:!w-screen',
      '[&_div.mobile-device.mobile_.puzzle-player]:!max-w-[100vw]',
      '[&_div.mobile-device.mobile_.puzzle-player]:!ml-[calc(-50vw+50%)]',
    ],
    linkText: `mb-3 flex items-center gap-1.25 text-preamble-sm hover:underline`,
  },
});

export default Object.assign(KeesingGames, { Icon });
