import { ArticleDateTheme } from '@/components/ArticleDate/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticleDateTheme });

const Base = $({ as: 'div', slot: 'base' });
const Date = $({ as: 'span', slot: 'date' });
const UpdatedDate = $({ as: 'span', slot: 'updatedDate' });

export const ArticleDate = Object.assign(Base, {
  Date,
  UpdatedDate,
});
