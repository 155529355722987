import { ButtonWith } from '@/components/Button';
import { CourseCountdownTheme } from '@/components/CourseCountdown/theme';
import { Timer as StandaloneTimer } from '@/components/Timer';
import { useTheme } from '@/styles/CourseCountdown';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: CourseCountdownTheme });

const Base = $({ as: 'div', slot: 'base' });
const Logo = $({ as: 'picture', slot: 'logo' });
const Description = $({ as: 'p', slot: 'description' });
const Timer = GenericSlot({ as: StandaloneTimer, theme: CourseCountdownTheme.Timer });
// TODO: fix CourseCountdown theme when Button is refactored
const Button = ButtonWith({ theme: { useTheme } });

export const CourseCountdown = Object.assign(Base, {
  Logo,
  Description,
  Timer,
  Button,
});
