export const withTextAlign = (textAlign: 'left' | 'center' | 'right') => {
  switch (textAlign) {
    case 'left':
      return 'text-left';
    case 'center':
      return 'text-center';
    case 'right':
      return 'text-right';
    default:
      return null;
  }
};
