import { isString } from 'typesafe-utils';

const validAccessLevels = ['open', 'login', 'purchase'] as const;

export type AccessLevel = (typeof validAccessLevels)[number];

export const parseAccessLevel = (option?: string): AccessLevel => {
  const isValid = isString(option) && validAccessLevels.includes(option as any);
  return isValid ? (option as AccessLevel) : 'open';
};
