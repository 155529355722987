import { tw } from '../tw';

type TextColorConfig = { desktop?: string; mobile?: string };

export const withTextColor = (config?: TextColorConfig) => {
  const { desktop, mobile } = config ?? {};

  if (!desktop && !mobile) {
    return null;
  }

  return tw.join(mobile, desktop && `sm:${desktop}`);
};
