import { getImageServer } from '@/utils/getImageServer';
import { isClient } from '@/utils/isClient';
import { isConsentDisabled } from '@/utils/isConsentDisabled';
import { logger } from '@/utils/logger';
import { waitFor } from '@/utils/waitFor';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { isArrayNotEmpty } from 'typesafe-utils';
import { RecommendedArticlesData } from '.';
import { defaultRequestOptions, globalOptions, microserviceHostname } from './config';
import { CustomRequestOptions, RecommendedArticle } from './types';

export function createArticleDataStructure(articles: RecommendedArticle[]) {
  const items = articles?.map(({ article, meta }, index) => {
    const imgId = article?.frontCropUrl?.match('imageId=([^&]+)')?.[1];
    const imageServer = getImageServer();
    let imageUrl = '';
    const headline = article.teaserTitle || article.title || '';

    if (/heighty=|panoh=|cropw=/.test(article.frontCropUrl)) {
      imageUrl = `${imageServer}/image-${imgId}.jpg${article.frontCropUrl}&width=400&height=200`;
    } else {
      imageUrl = `${imageServer}/image${imgId}.jpg?imageId=${imgId}&width=400&height=200`;
    }

    const getRecommendationType = (type: string) => {
      const contextualTypes = ['article', 'cookie'];
      return contextualTypes.includes(type) ? 'contextual' : type;
    };

    const metaInfo = {
      model: meta.model,
      priority: meta.priority,
      group: meta.group,
      recommendation_type: getRecommendationType(meta.type),
    };

    const ctsAttrs = withTrackingAttributes({
      category: 'recommendation_engine',
      name: headline,
      extraInfo: [metaInfo],
      url: article.published_url,
      placement: `${index + 1}`,
    });

    const result: RecommendedArticlesData[number] = {
      links: {
        article: {
          href: article.published_url,
          ...ctsAttrs,
          options: { className: 'cts-tracking-object' },
        },
      },
      image: {
        src: imageUrl,
        alt: '',
      },
      headline,
      description: article.teaserKicker || article.kicker || '',
      caption: article.isNativeAd ? article.nativeAdLabel || article.section_tag : article.section_tag,
    };

    return result;
  });
  return items;
}

export function waitForCmpClosed() {
  return waitFor(
    () => {
      return isCmpClosed();
    },
    {
      checkInterval: globalOptions.cmpWaitCheckFrequency,
      timeout: globalOptions.cmpWaitTimeout,
    },
  );
}

export function isCmpClosed() {
  return isConsentDisabled || document.cookie.indexOf('OptanonAlertBoxClosed=') >= 0;
}

/**
 * Wait for Google Analytics to load. Controlled by globalOptions.gaWaitTimeout
 * and globalOptions.gaWaitCheckFrequency.
 */
export function waitForGoogleAnalytics() {
  return waitFor(
    () => {
      return isGoogleAnalyticsLoaded();
    },
    {
      checkInterval: globalOptions.gaWaitCheckFrequency,
      timeout: globalOptions.gaWaitTimeout,
    },
  );
}

export function isGoogleAnalyticsLoaded() {
  return isClient() && typeof window.ga === 'function' && typeof window.ga.getAll === 'function';
}

function getVisitorsGaId() {
  // If ga is not available/setup, return null.
  //
  if (!isGoogleAnalyticsLoaded()) {
    logger.error('window.ga or window.ga.getAll is not a function (i.e. GA not available/setup).');
    return null;
  }

  const all = window.ga.getAll();
  const gaId = Array.isArray(all) && isArrayNotEmpty(all) ? all[0].get('clientId') : null;

  if (gaId === null) {
    logger.error('Could not determine gaId for the current visitor.');
    return null;
  }

  if (!/^\d+\.\d+$/.test(gaId)) {
    logger.error('The gaId found did not match the expected format.');
    return null;
  }

  return gaId;
}

export function compileOptions(options: CustomRequestOptions) {
  const compiledOptions = { ...defaultRequestOptions, ...options };

  // Get the gaId if not provided.
  if (!compiledOptions.gaId) {
    compiledOptions.gaId = getVisitorsGaId();
  }

  // Set Microservice hostname if not provided.
  if (!compiledOptions.hostname) {
    compiledOptions.hostname = microserviceHostname;
  }

  return compiledOptions;
}
