import { useClassName } from '@/styles/ShowsSlider';
import { Component, ComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/mergeProps';

export interface ShowsSliderHeaderProps extends ComponentProps<'div'> {}

export const ShowsSliderHeaderComponent: Component<ShowsSliderHeaderProps> = ({ children, ...props }) => {
  const className = useClassName('header', props);

  return <div {...mergeProps({ className }, props)}>{children}</div>;
};
