import { LabradorComponents } from '@/components/labrador';
import type { LabradorComponent } from '@/types/component';
import { pathToComponent } from '@/utils/toComponent';

export const LabradorArticleTeaser: LabradorComponent = ({ type, data, meta, descendants }) => {
  const name = 'ArticleTeaser' + (pathToComponent(meta.path) || 'Default');
  const key = name as keyof typeof LabradorComponents;
  const ArticleTeaser = LabradorComponents[key] || LabradorComponents.ArticleTeaserDefault;

  return <ArticleTeaser {...{ type, data, meta, descendants }} />;
};
