/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneStrossle as ResolvedStandaloneStrossle } from 'base/components/standalone/Strossle';

export const Strossle: typeof ResolvedStandaloneStrossle = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneStrossle {...props} />
        </ErrorBoundary>
    );
});

export type StrossleProps = PropsFromComponent<typeof Strossle>;

/** @deprecated Use slot architecture instead */
export const StrossleWith = (extras: DynamicStandaloneExtras): typeof Strossle => {
    return function Strossle(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Strossle');
        return <ResolvedStandaloneStrossle {...mergeProps({ options: { theme } }, props)} />;
    }
}