/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneRichTextBox as ResolvedStandaloneRichTextBox } from 'base/components/standalone/RichTextBox';

export const RichTextBox: typeof ResolvedStandaloneRichTextBox = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneRichTextBox {...props} />
        </ErrorBoundary>
    );
});

export type RichTextBoxProps = PropsFromComponent<typeof RichTextBox>;

/** @deprecated Use slot architecture instead */
export const RichTextBoxWith = (extras: DynamicStandaloneExtras): typeof RichTextBox => {
    return function RichTextBox(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'RichTextBox');
        return <ResolvedStandaloneRichTextBox {...mergeProps({ options: { theme } }, props)} />;
    }
}