/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTwitter as ResolvedStandaloneTwitter } from 'base/components/standalone/Twitter';

export const Twitter: typeof ResolvedStandaloneTwitter = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTwitter {...props} />
        </ErrorBoundary>
    );
});

export type TwitterProps = PropsFromComponent<typeof Twitter>;

/** @deprecated Use slot architecture instead */
export const TwitterWith = (extras: DynamicStandaloneExtras): typeof Twitter => {
    return function Twitter(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Twitter');
        return <ResolvedStandaloneTwitter {...mergeProps({ options: { theme } }, props)} />;
    }
}