/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandalonePage500 as ResolvedStandalonePage500 } from 'base/components/standalone/Page500';

export const Page500: typeof ResolvedStandalonePage500 = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandalonePage500 {...props} />
        </ErrorBoundary>
    );
});

export type Page500Props = PropsFromComponent<typeof Page500>;

/** @deprecated Use slot architecture instead */
export const Page500With = (extras: DynamicStandaloneExtras): typeof Page500 => {
    return function Page500(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Page500');
        return <ResolvedStandalonePage500 {...mergeProps({ options: { theme } }, props)} />;
    }
}