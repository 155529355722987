/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "list_item_teaser_group_variant_default": "",
  "list_item_teaser_group_size_default": "",
  "list_item_teaser_group_colors_default": "",
  "list_item_teaser_image_variant_default": "",
  "list_item_teaser_image_size_default": "!mx-0",
  "list_item_teaser_image_colors_default": "",
  "list_item_teaser_caption_variant_default": "",
  "list_item_teaser_caption_size_default": "text-preheadline",
  "list_item_teaser_caption_colors_default": "text-gray-600",
  "list_item_teaser_headline_variant_default": "",
  "list_item_teaser_headline_size_default": "text-headline-xs sm:text-headline-sm",
  "list_item_teaser_headline_colors_default": "",
  "list_item_teaser_description_variant_default": "",
  "list_item_teaser_description_size_default": "text-body-sm sm:text-body mt-1",
  "list_item_teaser_description_colors_default": "",
  "list_item_teaser_variant_default": "grid grid-cols-2 sm:grid-cols-1",
  "list_item_teaser_size_default": "gap-3 sm:gap-2",
  "list_item_teaser_colors_default": "",
  "list_item_variant_default": "",
  "list_item_size_default": "mb-6 sm:mb-0 md:mb-6",
  "list_item_colors_default": "",
  "list_variant_default": "grid sm:grid-cols-2 md:grid-cols-4",
  "list_colors_default": "",
  "list_size_default": "gap-3 sm:gap-6",
  "headline_variant_default": "block",
  "headline_colors_default": "",
  "headline_size_default": "mb-3.5 md:mb-4.5 text-headline-xs sm:text-headline",
  "variant_default": "relative wings",
  "colors_default": "bg-[url('/image/recommended-articles-background.jpg')] wings-[url('/image/recommended-articles-background.jpg')]",
  "size_default": "py-4 md:py-6 my-6 px-6",
  "list_item_teaser_group_variant_horizontal": "gap-1"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "RecommendedArticles");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;