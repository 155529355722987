import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Heading } from './Heading';

export interface StandaloneHeadingProps extends StandaloneComponentProps<typeof Heading> {
  content?: React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

export const StandaloneHeading: StandaloneComponent<StandaloneHeadingProps> = ({ content, ...props }) => {
  return <Heading {...props}>{content}</Heading>;
};
