import { SecondNativeArticleCampaignLocalStorage } from '@/types/secondNativeArticleCampaign';
import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'react-use';

const CONST_ARRAY: SecondNativeArticleCampaignLocalStorage[] = [];

export const useSeenArticleCampaignsLocalStorage = (): [
  SecondNativeArticleCampaignLocalStorage[],
  (campaignId: string) => void
] => {
  const [seenCampaigns, setSeenCampaigns] = useLocalStorage<SecondNativeArticleCampaignLocalStorage[]>(
    'secondNativeArticleCampaignIdsSeen',
    []
  );

  const addSeenCampaign = useCallback(
    (campaignId: string) => {
      setSeenCampaigns((prevCampaigns) => {
        const existingCampaign = prevCampaigns?.find((seen) => seen.campaignId === campaignId);

        if (existingCampaign) {
          return prevCampaigns?.map((campaign) =>
            campaign.campaignId === campaignId
              ? { ...campaign, userFrequency: (campaign.userFrequency ?? 1) + 1 }
              : campaign
          );
        }

        const newCampaign: SecondNativeArticleCampaignLocalStorage = {
          campaignId,
          userFrequency: 1,
        };
        return [...(prevCampaigns ?? []), newCampaign];
      });
    },
    [setSeenCampaigns]
  );

  return useMemo(() => [seenCampaigns ?? CONST_ARRAY, addSeenCampaign], [addSeenCampaign, seenCampaigns]);
};
