import { ArticleTeaserBodytextTheme } from '@/components/ArticleTeaser/Bodytext/theme';
import { Image as StandaloneImage } from '@/components/Image';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: ArticleTeaserBodytextTheme });

const Base = $({ as: 'div', slot: 'base' });
const Category = $({ as: 'span', slot: 'category' });
const Content = $({ as: 'div', slot: 'content' });
const Description = $({ as: 'span', slot: 'description' });
const Footer = $({ as: 'span', slot: 'footer' });
const Group = $({ as: 'div', slot: 'group' });
const Headline = $({ as: 'span', slot: 'headline' });
const Image = GenericSlot({ as: StandaloneImage, theme: ArticleTeaserBodytextTheme.Image });

export const ArticleTeaserBodytext = Object.assign(Base, {
  Category,
  Content,
  Description,
  Footer,
  Group,
  Headline,
  Image,
});
