import { pick } from 'lodash-es';
import type { CSSProperties } from 'react';

export const labStyleDictionary = {
  background: 'bg',
  color: 'color',
  fontFamily: 'font',
  fontSize: 'size',
  fontWeight: 'weight',
  lineHeight: 'leading',
  marginBottom: 'mb',
  marginLeft: 'ml',
  marginRight: 'mr',
  marginTop: 'mt',
  paddingBottom: 'pb',
  paddingLeft: 'pl',
  paddingRight: 'pr',
  paddingTop: 'pt',
  textAlign: 'align',
};

export type LabStyleKey = keyof typeof labStyleDictionary;

export const pickLabStyle = (meta: Record<string, any>) => {
  return pick(meta, Object.keys(labStyleDictionary)) as Record<LabStyleKey, { desktop?: string; mobile?: string }>;
};

export const getLabStyle = (meta: Record<string, any>) => {
  const style = pickLabStyle(meta);

  return Object.entries(style).reduce((result, [key, { desktop, mobile }]) => {
    const resolvedKey = labStyleDictionary[key as LabStyleKey];

    return {
      ...(mobile ? { [`--lab-${resolvedKey}`]: mobile } : undefined),
      ...(desktop ? { [`--lab-sm-${resolvedKey}`]: desktop } : undefined),
      ...result,
    };
  }, {} as CSSProperties);
};
