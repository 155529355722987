import { TimerTheme } from '@/components/Timer/theme';
import { tw } from '@/utils/tw';

const Timer = tw.theme({
  extend: TimerTheme,
});

const ConfirmationTheme = tw.theme({
  slots: {
    base: `p-6`,
    title: ``,
    banner: ``,
    bannerHeader: ``,
    bannerParagraph: ``,
    content: ``,
  },
});

export default Object.assign(ConfirmationTheme, { Timer });
