import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';
import { KeesingGamesTheme } from '@/components/KeesingGames/theme';
import { Icon as StandaloneIcon } from '@/components/Icon';

const $ = GenericSlotFactory({ theme: KeesingGamesTheme });

const Base = $({ as: 'div' });
const PuzzlePortal = $({ as: 'div', slot: 'puzzlePortal' });
const LinkText = $({ as: 'span', slot: 'linkText' });
const Icon = GenericSlot({ as: StandaloneIcon, theme: KeesingGamesTheme.Icon });

export const KeesingGames = Object.assign(Base, { PuzzlePortal, LinkText, Icon });
