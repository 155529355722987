/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorDirektBanner as ResolvedLabradorDirektBanner } from 'base/components/labrador/DirektBanner';

export const LabradorDirektBanner: typeof ResolvedLabradorDirektBanner = withLabradorInstanceof((props) => {
    return <ResolvedLabradorDirektBanner {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorDirektBannerProps = PropsFromComponent<typeof LabradorDirektBanner>;
