import { tw } from '@/utils/tw';

const Skeleton = tw.theme({
  base: [
    'h-full',
    'w-full',
    'relative',
    'overflow-hidden',
    'bg-gray-100',
    'after:block',
    'after:h-full',
    'after:absolute',
    'after:shadow-[0px_0px_60px_60px]',
    'after:shadow-white',
    'after:animate-skeleton',
  ],
});

export default Skeleton;
