import { Heading } from '@/components/Heading';
import { LabradorComponent } from '@/types/component';

export const LabradorHeading: LabradorComponent = ({ data, meta }) => {
  return (
    <Heading
      content={data.content}
      as={data.tag}
      options={{
        className: meta.className,
      }}
    />
  );
};
