import { propClassName } from '@/styles/Quotebox';
import { ClassNameProp, Component } from '@/types/component';
import { cn } from '@/utils/cn';
import NextImage from 'next/image';
import { useQuoteboxContext } from './Quotebox';

const variantSize: Record<string, { width: number; height: number }> = {
  primary: {
    width: 24,
    height: 18,
  },
  secondary: {
    width: 9.5,
    height: 7,
  },
};

export interface QuoteboxQuotationMarkProps {
  variant?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp;
  colors?: ClassNameProp;
}

export const QuoteboxQuotationMarkComponent: Component<QuoteboxQuotationMarkProps> = ({ variant, size, colors }) => {
  const context = useQuoteboxContext({ variant, size, colors });

  const variantClassName = propClassName('quotationMark_variant', context.variant, 'primary');
  const sizeClassName = propClassName('quotationMark_size', context.size);
  const colorsClassName = propClassName('quotationMark_colors', context.colors);

  const componentClassName = cn(variantClassName, sizeClassName, colorsClassName);

  const { width, height } = variantSize[context.variant ?? 'primary'];

  return (
    <NextImage
      className={componentClassName}
      src="/common/image/quotation-mark.svg"
      alt="quotation-mark"
      width={width}
      height={height}
      unoptimized
    />
  );
};
