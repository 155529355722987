/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorCourseCountdown as ResolvedLabradorCourseCountdown } from 'base/components/labrador/CourseCountdown';

export const LabradorCourseCountdown: typeof ResolvedLabradorCourseCountdown = withLabradorInstanceof((props) => {
    return <ResolvedLabradorCourseCountdown {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorCourseCountdownProps = PropsFromComponent<typeof LabradorCourseCountdown>;
