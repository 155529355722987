import { propClassName } from '@/styles/NewsletterSignup';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface NewsletterSignupDescriptionProps extends ComponentProps<'p'> {
  colors?: ClassNameProp<'primary' | 'secondary'>;
  size?: ClassNameProp<'default'>;
  variant?: ClassNameProp<'default'>;
}

export const NewsletterSignupDescriptionComponent: Component<NewsletterSignupDescriptionProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('description_colors', colors, 'primary');
  const sizeClassName = propClassName('description_size', size ?? variant, 'default');
  const variantClassName = propClassName('description_variant', variant, 'default');
  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <p className={componentClassName} {...props}>
      {children}
    </p>
  );
};
