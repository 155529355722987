import { InputCaption as StandaloneInputCaption } from '@/components/InputCaption';
import { TextAreaTheme } from '@/components/TextArea/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TextAreaTheme });

const Base = $({ as: 'div', slot: 'base' });
const Input = $({ as: 'textarea', slot: 'input' });
const Label = $({ as: 'label', slot: 'label' });
const Group = $({ as: 'div', slot: 'group' });
const Caption = GenericSlot({ as: StandaloneInputCaption, theme: TextAreaTheme.Caption });

export const TextArea = Object.assign(Base, { Input, Label, Group, Caption });
