/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorSecondNativeArticle as ResolvedLabradorSecondNativeArticle } from 'base/components/labrador/SecondNativeArticle';

export const LabradorSecondNativeArticle: typeof ResolvedLabradorSecondNativeArticle = withLabradorInstanceof((props) => {
    return <ResolvedLabradorSecondNativeArticle {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorSecondNativeArticleProps = PropsFromComponent<typeof LabradorSecondNativeArticle>;
