/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorArticleFooter as ResolvedLabradorArticleFooter } from 'base/components/labrador/ArticleFooter';

export const LabradorArticleFooter: typeof ResolvedLabradorArticleFooter = withLabradorInstanceof((props) => {
    return <ResolvedLabradorArticleFooter {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorArticleFooterProps = PropsFromComponent<typeof LabradorArticleFooter>;
