import c from '@/styles/InstagramArticlesList';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { InstagramArticlesListArticleComponent } from './InstagramArticlesList.Article';
import { InstagramArticlesListArticleGroupComponent } from './InstagramArticlesList.Article.Group';
import { InstagramArticlesListArticleHeadlineComponent } from './InstagramArticlesList.Article.Headline';
import { InstagramArticlesListArticleImageComponent } from './InstagramArticlesList.Article.Image';
import { InstagramArticlesListDescriptionComponent } from './InstagramArticlesList.Description';
import { InstagramArticlesListHeadlineComponent } from './InstagramArticlesList.Headline';

export interface InstagramArticlesListProps extends ComponentProps<'section'> {
  variant?: ClassNameProp<'default'>;
  colors?: ClassNameProp<'primary'>;
  size?: ClassNameProp<'default'>;
}

const InstagramArticlesListComponent: Component<InstagramArticlesListProps> = ({
  variant,
  colors,
  size,
  className,
  children,
  ...props
}) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const colorsClassName = getPropStyles('colors', colors, 'primary');
  const sizeClassName = getPropStyles('size', size, 'default');

  const componentClassName = cn(variantClassName, colorsClassName, sizeClassName, className);

  return (
    <section className={componentClassName} {...props}>
      {children}
    </section>
  );
};

export const InstagramArticlesList = Object.assign(InstagramArticlesListComponent, {
  Headline: InstagramArticlesListHeadlineComponent,
  Description: InstagramArticlesListDescriptionComponent,
  Article: Object.assign(InstagramArticlesListArticleComponent, {
    Group: InstagramArticlesListArticleGroupComponent,
    Headline: InstagramArticlesListArticleHeadlineComponent,
    Image: InstagramArticlesListArticleImageComponent,
  }),
});
