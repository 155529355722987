import { TimerTheme } from '@/components/Timer/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: TimerTheme });

const Base = $({ as: 'div', slot: 'base' });
const Title = $({ as: 'h4', slot: 'title' });
const Group = $({ as: 'div', slot: 'group' });
const Duration = $({ as: 'div', slot: 'duration' });
const DurationLabel = $({ as: 'span', slot: 'durationLabel' });
const Finished = $({ as: 'h4', slot: 'finished' });

export const Timer = Object.assign(Base, {
  Title,
  Group,
  Duration,
  DurationLabel,
  Finished,
});
