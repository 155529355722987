import { ButtonWith } from '@/components/Button';
import { useClassName, useTheme } from '@/styles/SaveArticleButton';
import { PropsFromComponent } from '@/types/component';
import { GenericComponent } from 'base/components/GenericComponent';
import { SaveArticleButtonModalComponent } from './SaveArticleButton.Modal';

const Button = ButtonWith({ theme: { useTheme } });

export type SaveArticleButtonProps = PropsFromComponent<typeof SaveArticleButton>;

export const SaveArticleButton = Object.assign(GenericComponent({ styles: { useClassName } }), {
  Modal: SaveArticleButtonModalComponent,
  Button,
});
