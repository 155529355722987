import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Slider } from './Slider';

export type StandaloneSliderProps = StandaloneComponentProps<typeof Slider> & {
  slides?: React.ReactNode[];
};

export const StandaloneSlider: StandaloneComponent<StandaloneSliderProps> = ({ slides, ...props }) => {
  return (
    <Slider slides-per-view="auto" {...props}>
      {slides?.map((slide, index) => {
        return <Slider.Slide key={index}>{slide}</Slider.Slide>;
      })}
    </Slider>
  );
};
