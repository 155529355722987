import { LoopAroundTheme } from '@/components/LoopAround/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: LoopAroundTheme });

const Base = $({ as: 'div', slot: 'base' });
const Reel = $({ as: 'div', slot: 'reel' });

export const LoopAround = Object.assign(Base, {
  Reel,
});
