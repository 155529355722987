import { ButtonProps } from '@/components/Button';
import { TimerProps } from '@/components/Timer';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { ReactNode } from 'react';
import { CourseCountdown } from './CourseCountdown';

export interface StandaloneCourseCountdownProps extends StandaloneComponentProps<typeof CourseCountdown> {
  logo?: ReactNode;
  timer?: TimerProps;
  description?: ReactNode;
  button?: ButtonProps;
}

export const StandaloneCourseCountdown: StandaloneComponent<StandaloneCourseCountdownProps> = ({
  logo,
  timer,
  button,
  description,
  ...props
}) => {
  return (
    <CourseCountdown {...props}>
      {logo && <CourseCountdown.Logo>{logo}</CourseCountdown.Logo>}
      <CourseCountdown.Timer {...mergeProps(props?.options?.$timer, timer)} />
      {description && <CourseCountdown.Description>{description}</CourseCountdown.Description>}
      <CourseCountdown.Button {...mergeProps(props?.options?.$button, button)} />
    </CourseCountdown>
  );
};
