/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneTextMultiline as ResolvedStandaloneTextMultiline } from 'base/components/standalone/TextMultiline';

export const TextMultiline: typeof ResolvedStandaloneTextMultiline = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneTextMultiline {...props} />
        </ErrorBoundary>
    );
});

export type TextMultilineProps = PropsFromComponent<typeof TextMultiline>;

/** @deprecated Use slot architecture instead */
export const TextMultilineWith = (extras: DynamicStandaloneExtras): typeof TextMultiline => {
    return function TextMultiline(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'TextMultiline');
        return <ResolvedStandaloneTextMultiline {...mergeProps({ options: { theme } }, props)} />;
    }
}