import { MostRead } from '@/components/MostRead';
import { LabradorComponent } from '@/types/component';
import { Content } from '@/types/content';

import { renderContentComponent } from 'modules/dynamic/components';

export const LabradorMostRead: LabradorComponent = ({ type, data, meta, descendants }) => {
  const slides = descendants.map((descendant: Content) => {
    const { data, meta, children } = descendant;

    return {
      caption: meta.isNativeAd ? meta.nativeAdLabel : data.category,
      description: data.title,
      publishedUrl: data.publishedUrl,
      image: children[0] ? renderContentComponent(children[0]) : undefined,
    };
  });

  if (!slides.length) {
    return null;
  }

  return <MostRead headline={data.title} slides={slides} data-content-index={meta.contentIndex} />;
};
