import { tw } from '@/utils/tw';

const ArticleDate = tw.theme({
  slots: {
    base: ``,
    date: ``,
    updatedDate: `text-gray-600`,
  },
});

export default ArticleDate;
