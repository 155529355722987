/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorArticle as ResolvedLabradorArticle } from 'base/components/labrador/Article';

export const LabradorArticle: typeof ResolvedLabradorArticle = withLabradorInstanceof((props) => {
    return <ResolvedLabradorArticle {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorArticleProps = PropsFromComponent<typeof LabradorArticle>;
