/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorEmphasis as ResolvedLabradorEmphasis } from 'base/components/labrador/Emphasis';

export const LabradorEmphasis: typeof ResolvedLabradorEmphasis = withLabradorInstanceof((props) => {
    return <ResolvedLabradorEmphasis {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorEmphasisProps = PropsFromComponent<typeof LabradorEmphasis>;
