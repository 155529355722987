import { Tag } from '@/components/Tag';
import { LabradorComponent } from '@/types/component';
import { tw } from '@/utils/tw';
import { usePathname } from 'next/navigation';

const tags = [
  { value: 'Lunch/Middag', href: '/21-dagar-kost-lunch-middag' },
  { value: 'Frukost/Mellanmål', href: '/21-dagar-kost-frukost-mellis' },
  { value: 'Vegetariskt', href: '/21-dagar-kost-veg' },
  { value: 'Fisk', href: '/21-dagar-kost-fisk' },
  { value: 'Kött', href: '/21-dagar-kost-k%C3%B6tt' },
  { value: 'Fågel', href: '/21-dagar-kost-f%C3%A5gel' },
  { value: 'Efterrätt', href: '/21-dagar-kost-efterr%C3%A4tt' },
];

export const MabraLabradorContentTags: LabradorComponent = () => {
  const pathname = usePathname();

  return (
    <div className="my-3 flex flex-col items-center gap-y-4">
      <p className="text-black text-headline-xs">Vill du se fler recept?</p>
      <div className="flex flex-row flex-wrap justify-center gap-3 p-3">
        {tags.map(({ value, href }, index) => (
          <Tag
            key={index}
            value={value}
            link={{ href }}
            data-active={pathname === href ? '' : undefined}
            options={{
              className: tw.join([
                'text-black',
                'bg-white',
                'border-1',
                'border-yellow-sea-400',

                'hover:bg-yellow-sea-300',
                'hover:border-yellow-300',

                'active:from-yellow-300',
                'active:to-yellow-400',
                'active:border-yellow-400',

                'data-[active]:bg-yellow-sea-300',
                'data-[active]:border-yellow-300',
              ]),
            }}
          />
        ))}
      </div>
    </div>
  );
};
