/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorAdTemplate as ResolvedLabradorAdTemplate } from 'base/components/labrador/AdTemplate';

export const LabradorAdTemplate: typeof ResolvedLabradorAdTemplate = withLabradorInstanceof((props) => {
    return <ResolvedLabradorAdTemplate {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorAdTemplateProps = PropsFromComponent<typeof LabradorAdTemplate>;
