/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorInstagram as ResolvedLabradorInstagram } from 'base/components/labrador/Instagram';

export const LabradorInstagram: typeof ResolvedLabradorInstagram = withLabradorInstanceof((props) => {
    return <ResolvedLabradorInstagram {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorInstagramProps = PropsFromComponent<typeof LabradorInstagram>;
