import { RadioButtonTheme } from '@/components/RadioButton/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: RadioButtonTheme });

const Base = $({ as: 'div', slot: 'base' });
const Label = $({ as: 'label', slot: 'label' });
const Input = $({ as: 'input', slot: 'input' });

export const RadioButton = Object.assign(Base, { Input, Label });
