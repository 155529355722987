import { Device } from '@/types/app';
import { Content } from '@/types/content';
import { getUrl } from '@/utils/getUrl';
import { http } from '@/utils/http';
import { isClient } from '@/utils/isClient';

export const fetchNativeArticle = async (articleId: string, device: Device): Promise<Content | null> => {
  const url = getUrl('/api/page');

  if (!url) {
    return null;
  }

  const params = new URLSearchParams({
    route: `article/${articleId}`,
    device,
    isSecondNativeArticle: 'true',
  });

  url.search = params.toString();

  const response = await http.get(url.href);
  const data = (response?.data as Content) || null;

  return data;
};

// Push updated tracking data to the datalayer
// GTM & CTS reads data from window.aller_ga and window.cts.metadata
export const updateAllerGaData = (
  allerGAData: Record<string, string>,
  trackingData: Record<string, string>,
  isSecondNative: boolean,
) => {
  if (isClient()) {
    const updatedData = {
      affiliateBool: window.aller_ga?.containAffiliate,
      isAffiliate: window.aller_ga?.containAffiliate,
      isSecondNative,
    };

    window.aller_ga = {
      ...(window.aller_ga || {}),
      ...allerGAData,
      ...updatedData,
    };

    window.cts.metadata = {
      ...(window.cts.metadata_ga || {}),
      ...trackingData,
      ...updatedData,
    };
  }
};
