import { useStable, useStableOptions } from '@/hooks/useStable';
import { ClassNameProvider } from '@/styles/SliderSlide';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode, useMemo } from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { isString } from 'typesafe-utils';
import { Slide } from './Slide';

export interface StandaloneSliderSlideProps extends StandaloneComponentProps<typeof Slide> {
  url?: string;
  imageSrc?: string;
  title?: ReactNode;
}

export const StandaloneSliderSlide: StandaloneComponent<StandaloneSliderSlideProps> = ({
  title,
  url,
  imageSrc,
  options,
  ...props
}) => {
  const { $caption, $link, $image, ...$base } = { ...options };

  const stableProviderProps = useStable({
    value: $base,
    options: {
      fallbacks: {
        size: 'variant',
        colors: 'variant',
      },
    } as const,
  });

  const memoContent = useMemo(
    () => (
      <>
        <Slide.Image src={imageSrc} alt={isString(title) ? title : ''} {...$image} />
        {title && <Slide.Caption {...$caption}>{title}</Slide.Caption>}
      </>
    ),
    [$caption, $image, imageSrc, title],
  );

  const stableOptions = useStableOptions({ rel: 'nofollow noopener' }, $link);

  return (
    <ClassNameProvider {...stableProviderProps}>
      <Slide {...$base} {...props}>
        <Slide.Link href={url} target="_blank" content={memoContent} options={stableOptions} />
      </Slide>
    </ClassNameProvider>
  );
};

/**
 * When using the `StandaloneSlide` you must set the `displayName`
 * property to `SwiperSlide` for `Swiper` to work properly.
 *
 * @example
 *
 * Slide.displayName = 'SwiperSlide';
 */
