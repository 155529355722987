import { MabraStandaloneBadge } from '@/mabra/components/Badge';
import { LabradorComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { LabradorJwVideoTeaser } from 'base/components/labrador/JwVideo/Teaser';

export const MabraLabradorJwVideoTeaser: LabradorComponent = (props) => {
  const { isFrontPage, badge, badgeData } = props.data;

  return (
    <>
      <LabradorJwVideoTeaser
        {...mergeProps(
          {
            data: {
              headline: '',
              options: {
                ...(isFrontPage
                  ? {
                      $content: {
                        $hint: {
                          colors: 'secondary',
                        },
                      },
                    }
                  : {
                      colors: 'primary',
                      $content: {
                        colors: 'primary',
                      },
                    }),
              },
            },
          },
          props,
        )}
      />
      {badge && <MabraStandaloneBadge data={badgeData} variant="bodytext" />}
    </>
  );
};
