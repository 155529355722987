import { Link } from '@/components/Link';
import { List } from '@/components/List';
import { useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import { StandaloneComponent } from '@/types/component';
import { StandaloneConfirmation, StandaloneConfirmationProps } from 'base/components/Confirmation';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import getConfig from 'modules/config';
import ConfirmationTheme from './theme';

const $ = GenericSlotFactory({ theme: ConfirmationTheme });
const BannerParagraph = $({ as: 'p', slot: 'bannerParagraph' });

export const MabraStandaloneConfirmation: StandaloneComponent<StandaloneConfirmationProps> = (props) => {
  const { closeTime } = getConfig('payments') ?? {};

  const { logInUrl, isUserLoggedIn } = useUserAuth();

  const stableConfirmation = useStableProps(
    {
      title: '21 dagar kost & träning',
      banner: {
        header: 'Tack för din anmälan – innan vi börjar:',
        content: (
          <>
            <BannerParagraph>
              Vi har skickat ett bekräftelsemejl till e-postadressen du angav. För att få tillgång till tjänsten{' '}
              <span className="bold">måste du först göra två viktiga saker:</span>
            </BannerParagraph>
            <List
              as="ol"
              items={[
                'Säkerställ att du har fått en bekräftelse skickad till din mejl',
                'Klicka på länken i mejlet för att bekräfta ditt konto',
              ]}
              options={{
                className:
                  'list-decimal flex flex-col gap-3 text-headline-sm md:text-headline-md pl-6 md:pl-7 mb-4 md:mb-6',
              }}
            />
            <BannerParagraph>
              Om du inte har fått ett bekräftelsemejl inom 72 timmar kontakta kundservice{' '}
              <Link
                href="mailto:21dagar@mabra.com"
                content="21dagar@mabra.com"
                options={{ className: 'text-cherry-blossom-500 font-bold underline hover:no-underline' }}
              />
            </BannerParagraph>
            <BannerParagraph className="bold">
              Du måste bekräfta ditt konto för att få tillgång till tjänsten. Vi rekommenderar att du gör det direkt.
            </BannerParagraph>
          </>
        ),
      },
      timer: { title: 'Utmaningen börjar om:', finishTime: closeTime },
      content: isUserLoggedIn ? (
        <Link href="/mina-sidor" content="Gå till mina sidor" options={{ className: 'underline hover:no-underline' }} />
      ) : (
        <>
          Redan medlem?{' '}
          <Link href={logInUrl} content="Logga in här" options={{ className: 'underline hover:no-underline' }} />
        </>
      ),
      button: {
        content: 'Läs det senaste från MåBra',
        link: { href: '/' },
        // TODO: Move Button classes to theme after migrating to new architecture
        options: { className: 'mx-auto md:mb-4 w-fit !block' },
      },
    },
    props,
  );

  return <StandaloneConfirmation {...stableConfirmation} />;
};
