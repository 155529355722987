import { tw } from '@/utils/tw';

const Timer = tw.theme({
  slots: {
    base: ``,
    title: ``,
    group: `gap-3 flex justify-center`,
    duration: `flex flex-col items-center justify-center`,
    durationLabel: ``,
    finished: ``,
  },
});

export default Timer;
