import { Facebook } from '@/components/Facebook';
import { LabradorComponent } from '@/types/component';

export const LabradorFacebook: LabradorComponent = ({ type, data, meta }) => {
  return (
    <Facebook
      url={data.url}
      options={{
        isVideo: data.isVideo,
      }}
      data-content-index={meta.contentIndex}
    />
  );
};
