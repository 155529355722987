import { tw } from '@/utils/tw';

export function getViewportVisibilityClass({
  viewportVisibility = '',
  hideViewport = { mobile: false, desktop: false },
  display = 'block',
}: {
  viewportVisibility: string; // Lab3 property,
  hideViewport: { mobile: boolean; desktop: boolean }; //Lab4 property,
  display: string;
}): string {
  const hideMobileClass = `hidden sm:${display}`;
  const hideDesktopClass = 'sm:hidden';

  const hideMobile = hideViewport.mobile || viewportVisibility === 'desktop';
  const hideDesktop = hideViewport.desktop || viewportVisibility === 'mobile';

  return tw.join(
    (hideDesktop || (!hideDesktop && !hideMobile)) && display,
    hideDesktop && hideDesktopClass,
    hideMobile && hideMobileClass,
  );
}
