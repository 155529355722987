/**
 * Converts an authors name into a slug. Copied from se-calico almost verbatim to keep consistent slugs
 *
 * @param authorName
 * @returns a url friendly slug
 */
export const authorNameToSlug = (authorName: string): string => {
  const preprocessdAuthorName = authorName.trim().toLowerCase();

  const replaceFrom = 'àáäåâèéëêìíïîòóöôùúüûñç·/_,:;';
  const replaceTo = 'aaaaaeeeeiiiioooouuuunc------';

  let replacedAuthorName = '';
  for (let i = 0; i < preprocessdAuthorName.length; i++) {
    const char = preprocessdAuthorName.charAt(i);
    const fromIndex = replaceFrom.indexOf(char);
    replacedAuthorName += fromIndex > -1 ? replaceTo[fromIndex] : char;
  }

  return replacedAuthorName
    ?.replace(/[^a-z0-9 -]/g, '')
    .replace(/\s+/g, '_')
    .replace(/-+/g, '_');
};
