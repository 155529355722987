import { MabraStandaloneBadge } from '@/mabra/components/Badge';
import { StandaloneComponent } from '@/types/component';
import {
  StandaloneArticleTeaserBodytext,
  StandaloneArticleTeaserBodytextProps,
} from 'base/components/ArticleTeaser/Bodytext';
import { ArticleBadgeData } from 'lib/labrador/articleBadge';

interface MabraStandaloneArticleTeaserBodytextProps extends StandaloneArticleTeaserBodytextProps {
  badge?: ArticleBadgeData;
}

export const MabraStandaloneArticleTeaserBodytext: StandaloneComponent<MabraStandaloneArticleTeaserBodytextProps> = ({
  badge,
  ...props
}) => {
  return (
    <div>
      <StandaloneArticleTeaserBodytext {...props} />
      {badge && <MabraStandaloneBadge data={badge} variant="bodytext" />}
    </div>
  );
};
