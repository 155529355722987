import { Byline } from '@/components/Byline';
import { LabradorComponent } from '@/types/component';

export const LabradorByline: LabradorComponent = ({ type, data, meta }) => {
  const author = [data.firstName, data.lastName].filter(Boolean).join(' ');

  return (
    <Byline
      slug={data.slug}
      type={data.type}
      author={author}
      image={data.image}
      text={data.text}
      options={data.options}
    />
  );
};
