/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorByline as ResolvedLabradorByline } from 'base/components/labrador/Byline';

export const LabradorByline: typeof ResolvedLabradorByline = withLabradorInstanceof((props) => {
    return <ResolvedLabradorByline {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorBylineProps = PropsFromComponent<typeof LabradorByline>;
