/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneAffiliateItem as ResolvedStandaloneAffiliateItem } from 'base/components/standalone/AffiliateItem';

export const AffiliateItem: typeof ResolvedStandaloneAffiliateItem = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneAffiliateItem {...props} />
        </ErrorBoundary>
    );
});

export type AffiliateItemProps = PropsFromComponent<typeof AffiliateItem>;

/** @deprecated Use slot architecture instead */
export const AffiliateItemWith = (extras: DynamicStandaloneExtras): typeof AffiliateItem => {
    return function AffiliateItem(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'AffiliateItem');
        return <ResolvedStandaloneAffiliateItem {...mergeProps({ options: { theme } }, props)} />;
    }
}