import { useClassName } from '@/styles/ListGrid';
import { GenericComponentFactory } from 'base/components/GenericComponent';

const GenericComponent = GenericComponentFactory({ useClassName });

const Base = GenericComponent({ as: 'div' });
const Headline = GenericComponent({ as: 'h1', styles: { key: 'headline' } });
const Description = GenericComponent({ as: 'p', styles: { key: 'description' } });
const List = GenericComponent({ as: 'ul', styles: { key: 'list' } });
const Item = GenericComponent({ as: 'li', styles: { key: 'list_item' } });

export const ListGrid = Object.assign(Base, { Headline, Description, List: Object.assign(List, { Item }) });
