import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Paragraph } from './Paragraph';

export interface StandaloneParagraphProps extends StandaloneComponentProps<typeof Paragraph> {
  content?: React.ReactNode;
}

export const StandaloneParagraph: StandaloneComponent<StandaloneParagraphProps> = ({ content, ...props }) => {
  return <Paragraph {...props}>{content}</Paragraph>;
};
