import { TextSubtitle } from '@/components/TextSubtitle';
import { LabradorComponent } from '@/types/component';
import { cn } from '@/utils/cn';
import { getLabStyle } from '@/utils/labStyle';
import { withBackgroundColor } from '@/utils/withBackgroundColor';
import { withTextColor } from '@/utils/withTextColor';

export const LabradorTextSubtitle: LabradorComponent = ({ type, data, meta, descendants, ...props }) => {
  const style = getLabStyle(data?.fieldSettings?.subtitle);
  const textColor = withTextColor(data?.fieldSettings?.subtitle?.textColor);
  const backgroundColor = withBackgroundColor(data?.fieldSettings?.subtitle?.textBackgroundColor);

  return (
    <TextSubtitle
      content={data.subtitle}
      options={{ className: cn('lab', textColor, backgroundColor), style }}
      {...props}
    />
  );
};
