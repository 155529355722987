import { useScheduled } from '@/hooks/useScheduled';
import { useStableProps } from '@/hooks/useStable';
import useUserAuth from '@/hooks/useUserAuth';
import BannerImage from '@/mabra/public/image/course/mabra-banner-image.png';
import { getUrl } from '@/utils/getUrl';
import { withTrackingAttributes } from '@/utils/withTrackingAttributes';
import { StandaloneCourseCountdown } from 'base/components/CourseCountdown';
import Tracking from 'base/components/TrackingComponent';
import getConfig from 'modules/config';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { getCountdownCopy } from './utils/getCountdownCopy';

const { closeTime } = getConfig('payments') ?? {};
const ctsUrl = getUrl('/21-dagar');

const ctsAttrs = withTrackingAttributes({
  category: 'promotion_banner',
  name: 'countdown',
  url: ctsUrl ? ctsUrl.toString() : '/21-dagar',
  extraInfo: [{ campaign: 'mabra_course_sep24', end_time: closeTime }],
});

export const MabraStandaloneCourseCountdown: typeof StandaloneCourseCountdown = (props) => {
  const { userTags } = useUserAuth();
  const isUserSubscribed = userTags.includes('mabra_fitness_course');

  const { asPath } = useRouter();
  const isMyPage = asPath.startsWith('/mina-sidor');

  const hasCourseStarted = useScheduled(props.timer?.finishTime ?? closeTime);

  const { title, description, button, hideCountdown } = getCountdownCopy({
    courseStarted: hasCourseStarted,
    isUserSubscribed,
    isMyPage,
  });

  const logo = useMemo(() => <NextImage src={BannerImage} alt={'Banner Image'} />, []);

  const stableCourseCountdown = useStableProps(
    {
      logo,
      timer: {
        title,
        finishTime: closeTime,
        finishContent: isMyPage && title,
      },
      description,
      button,
    },
    props,
  );

  if (hideCountdown) {
    return null;
  }

  return (
    <Tracking options={{ wrapChildren: true, ctsAttrs }}>
      <StandaloneCourseCountdown {...stableCourseCountdown} />
    </Tracking>
  );
};
