import { List as StandaloneList } from '@/components/List';
import { MoreEpisodesTheme } from '@/components/MoreEpisodes/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: MoreEpisodesTheme });

const Base = $({ as: 'section', slot: 'base' });
const Headline = $({ as: 'h2', slot: 'headline' });
const List = GenericSlot({ as: StandaloneList, theme: MoreEpisodesTheme.List });

export const MoreEpisodes = Object.assign(Base, {
  Headline,
  List,
});
