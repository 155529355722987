/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "list_item_colors_default": "",
  "list_item_size_default": "",
  "list_item_variant_default": "",
  "list_colors_default": "",
  "list_size_default": "gap-4 gap-x-6 md:gap-y-12",
  "list_variant_default": "grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4",
  "headline_colors_default": "",
  "headline_size_default": "text-headline-lg sm:text-headline-xl mb-4",
  "headline_variant_default": "",
  "description_colors_default": "",
  "description_size_default": "text-body-sm md:text-body-md mb-16",
  "description_variant_default": "",
  "colors_default": "",
  "size_default": "",
  "variant_default": ""
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "ListGrid");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;