import c from '@/styles/Instagram';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { useEffect } from 'react';

export interface InstagramProps extends ComponentProps<'div'> {
  variant?: ClassNameProp<'default'>;
  instagramHtml: string;
}

export const Instagram: Component<InstagramProps> = ({ variant, instagramHtml, className, ...props }) => {
  const getPropStyles = getPropStylesFactory(c);

  const variantClassName = getPropStyles('variant', variant, 'default');
  const componentClassName = cn(variantClassName, className);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.instagram.com/en_US/embeds.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  }, [instagramHtml]);

  return <div dangerouslySetInnerHTML={{ __html: instagramHtml }} className={componentClassName} {...props} />;
};
