import { StandaloneFront } from 'base/components/Front';
import { useAppState } from '@/hooks/useAppState';
import Tracking from 'base/components/TrackingComponent';

export const MabraStandaloneFront: typeof StandaloneFront = (props) => {
  const [{ metaPageType }] = useAppState();

  if (metaPageType === 'courses')
    return (
      <Tracking>
        <StandaloneFront {...props} />
      </Tracking>
    );

  return <StandaloneFront {...props} />;
};

export default MabraStandaloneFront;
