import { Image, ImageProps } from '@/components/Image';
import { useStableProps } from '@/hooks/useStable';
import { useTheme } from '@/styles/SliderSlide';
import { StandaloneComponent } from '@/types/component';

export type SlideImageProps = ImageProps;

export const SlideImageComponent: StandaloneComponent<SlideImageProps> = (props) => {
  const theme = useTheme('image');

  const stableProps = useStableProps(
    {
      options: {
        theme,
        width: '300',
        height: '300',
        sizes: '(max-width: 480px) 52vw, (max-width: 768px) 24vw, (max-width: 1200px) 20vw, 16vw',
      },
    },
    props,
  );

  return <Image alt="" {...stableProps} />;
};
