// This function converts coverbox bodytext specific format from lab3 etikett pages
// to standard HTML markup to make it compatible without rewriting all etikett pages config

// input - 'lorem ipsum [url=https://somecustomurl] link value [/url] lorem ipsum
// output - 'lorem ipsum <a href="https://somecustomurl"> link value </a> lorem ipsum

export const convertLinkFormat = (input?: string): string => {
  const regex = /\[url=(.*?)\](.*?)\[\/url\]/g;

  if (!input) return '';

  return input.replace(regex, (match, url, text) => {
    return `<a href=${url}>${text}</a>`;
  });
};
