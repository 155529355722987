import { BlipTheme } from '@/components/Blip/theme';
import { tw } from '@/utils/tw';

const DirektTheme = tw.theme({
  slots: {
    base: 'ml-2 mt-8',
    group: 'mb-2 flex items-center',
    heading: 'mb-2 inline-block pt-1 text-headline-lg sm:text-headline-xl',
    label: 'pl-2',
    description: 'text-body-sm',
  },
});

const Blip = tw.theme({
  extend: BlipTheme,
  base: 'mr-2 align-super',
});

export default Object.assign(DirektTheme, { Blip });
