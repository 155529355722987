import { Button, ButtonProps } from '@/components/Button';
import { propClassName } from '@/styles/ScrollPageControls';
import type { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';

export interface ScrollPageControlsButtonProps extends ExtendedStandaloneComponentProps<ButtonProps> {
  colors?: ClassNameProp;
  size?: ClassNameProp;
  variant?: ClassNameProp;
}

export const ScrollPageControlsButtonComponent: ExtendedStandaloneComponent<ScrollPageControlsButtonProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('button_colors', colors);
  const sizeClassName = propClassName('button_size', size);
  const variantClassName = propClassName('button_variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return <Button options={{ className: componentClassName, ...standaloneOptions }} {...standaloneProps} {...props} />;
};
