/* THIS FILE IS GENERATED. DO NOT EDIT */

import { PropsFromComponent } from '@/types/component';
import { withDebugMetadata } from '@/utils/withDebugMetadata';
import { withLabradorInstanceof } from '@/utils/withInstanceofProp';
import { LabradorLineBreak as ResolvedLabradorLineBreak } from 'base/components/labrador/LineBreak';

export const LabradorLineBreak: typeof ResolvedLabradorLineBreak = withLabradorInstanceof((props) => {
    return <ResolvedLabradorLineBreak {...props} {...withDebugMetadata({type: props.type, meta: props.meta})} />;
});

export type LabradorLineBreakProps = PropsFromComponent<typeof LabradorLineBreak>;
